.rbSelectWrapper {
  position: relative;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0;
  font: 13.3333px Arial;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  color: #000;
  background-color: #fff;
  cursor: default;
  overflow: visible !important;
}
.rbSelect {
  border: 1px solid #a9a9a9;
  border-image: initial;
  border-radius: 0;
  width: 100%;
}
.rbSelect:focus {
  outline: 1px solid #2390fd;
}
.rbSelectArrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
}
.rbSelectArrow.arrowUp {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
}
.rbSelectArrow.arrowDown {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #000;
}
.rbOptions {
  z-index: 100000;
  background: #fff;
  margin: 0;
  list-style: none;
  padding: 0;
  border: 1px solid #a9a9a9;
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
}
.rbOption.highlighted {
  color: #fff;
  background: #2390fd;
}
.rbSelectWrapper.simple {
  display: block;
}
.rbSelectWrapper.simple .rbSelectArrow {
  position: absolute;
  top: 15px;
  right: 10px;
}
.rbSelectWrapper.simple .rbSelectArrow.arrowUp {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6x solid #37474f;
}
.rbSelectWrapper.simple .rbSelectArrow.arrowDown {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #37474f;
}
.rbSelectWrapper.simple .rbSelect,
.rbSelectWrapper.simple .rbOption {
  cursor: pointer;
  color: #37474f;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #dcdee0;
  text-indent: 5px;
  transition: all 0.5s ease-out;
  padding-right: 26px;
  text-align: left;
  font-size: 13px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
}
.rbSelectWrapper.simple .rbOption {
  border: 1px solid transparent;
  border-bottom: 1px solid #dcdee0;
}
.rbSelectWrapper.simple .rbOption.highlighted {
  background: rgba(0,169,157,0.3);
}
