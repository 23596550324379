._2LZMZaI7yuCfiizS_GNjnF {
  font-size: 11px;
  line-height: 12px;
  color: #78909c;
}
.A6mWIJwwjAqxPLAY0Oxjb {
  position: relative;
}
.A6mWIJwwjAqxPLAY0Oxjb::before {
  position: absolute;
  top: 7px;
  right: 10px;
  font-family: "Material Icons", monospace;
  color: #b0bec5;
  font-size: 24px;
  content: '\E8B6';
  z-index: 1;
}
._2THFPWacelXH8Z9ob0qeAW {
  line-height: 36px;
  height: 36px;
  width: 100%;
  border: 1px solid #dcdee0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  background: #fff;
  border-radius: 3px;
  padding: 0 35px 0 5px;
}
._2THFPWacelXH8Z9ob0qeAW:placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
._2THFPWacelXH8Z9ob0qeAW::-moz-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
._2THFPWacelXH8Z9ob0qeAW::-webkit-input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
._2THFPWacelXH8Z9ob0qeAW:-ms-input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
._2THFPWacelXH8Z9ob0qeAW:input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
