.rbInputContainer {
  margin-top: 10px;
}
.rbInputLabel {
  display: block;
  font-size: 11px;
  margin: 0 0 3px 1px;
  font-weight: 400;
  color: #78909c;
  position: relative;
  transition: color 0.5s ease-out;
}
.rbInputLabelHint {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 9px;
  transition: color 0.5s ease-out;
}
.rbInput {
  height: 36px;
  width: 100%;
  border: 1px solid #dcdee0;
  color: #455a64;
  text-indent: 5px;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
}
.rbInputInvalid .rbInput {
  border-color: #f15a24;
}
.rbInputInvalid .rbInputLabelHint,
.rbInputInvalid .rbInputLabel {
  color: #f15a24;
}
.rbInputInvalid .rbInputError {
  background: #f15a24;
  color: #fff;
}
.rbInputError {
  height: 16px;
  color: #f15a24;
  font-size: 10px;
  font-weight: 600;
  padding: 0 4px;
}
.rbInputError-enter,
.rbInputError-leave-to {
  opacity: 0;
  transform: scale(0.7);
}
.rbInputError-enter-active,
.rbInputError-leave-active {
  transition: all 0.3s ease-out;
}
