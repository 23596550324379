.account-manage-users {
  color: #37474f;
  position: relative;
  overflow: hidden;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
.account-manage-users .loader {
  height: 100%;
}
.account-manage-users .header {
  flex: 0 0 auto;
  padding: 20px;
  border-bottom: 1px solid #eceff1;
  background: #fff;
}
.account-manage-users .header > div > i.material-icons {
  padding: 10px;
  background: #eceff1;
  border-radius: 100%;
  margin-right: 10px;
}
.account-manage-users .header .title {
  font-size: 12px;
  color: #8c999f;
}
.account-manage-users .header .sub-title {
  font-size: 20px;
}
.account-manage-users .footer {
  flex: 0 0 auto;
  padding: 20px;
  border-top: 1px solid #eceff1;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.account-manage-users .footer .On {
  color: #00a99d;
}
.account-manage-users .footer .Off {
  color: #f25b26;
}
.account-manage-users .flex {
  display: flex;
}
.account-manage-users .flex.row {
  align-items: center;
  justify-content: flex-start;
}
.account-manage-users .flex.column {
  flex-direction: column;
}
.account-manage-users .controls {
  margin-top: 10px;
  justify-content: space-between !important;
}
.account-manage-users .controls,
.account-manage-users .controls > div {
  align-items: flex-end !important;
}
.account-manage-users .controls .rfp-manager-group-by {
  font-size: 12px;
}
.account-manage-users .controls > .row {
  flex: 1;
}
.account-manage-users .button {
  padding: 2px 5px;
  align-items: center;
  display: flex;
  font-size: 12px;
  background: #fff linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  height: 27px;
}
.account-manage-users .button:not(.icon-only) i.material-icons {
  margin: 0 5px 0 0;
  font-size: 18px;
}
.account-manage-users .create {
  background: #00b8ab linear-gradient(0deg, #00b0a3 10%, #00b8ab 11.05%, #00b8ab 100%);
  color: #fff;
  border: 1px solid #00b8a9;
}
.account-manage-users .logo {
  position: relative;
  margin-right: 10px;
}
.account-manage-users .logo > img,
.account-manage-users .logo > div {
  height: 65px;
  width: 110px;
  object-fit: contain;
  border-radius: 3px;
  border: 1px solid #eceff1;
  background: #eceff1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-manage-users .logo > img i.material-icons,
.account-manage-users .logo > div i.material-icons {
  font-size: 50px;
}
.account-manage-users .logo > img {
  background: transparent;
}
.account-manage-users .autoVerification {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
