._1celhiFULK7kF4usAt1zFh {
  width: 400px;
  overflow: visible;
  border-radius: 1px solid;
}
.l_ZJjiBCO_8eyq9oHsWvl {
  font-size: 25px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._1zOL7xc9usitXRR0LHIJJX {
  display: flex;
  font-weight: lighter;
}
._2NoLgyHIka6lqjKUXrbPd2 {
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: rgba(242,60,24,0.8);
  margin-right: 10px;
}
._18TToeyBdi5JOL_uuC64t6 {
  padding: 5px;
  font-size: 18px;
  color: #78909c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Qd2lWI5akdRbfnb8OrvFt {
  background: rgba(242,60,24,0.8) !important;
  border: none !important;
}
