.YzuySN7gjowwUb82P2mZ5 p {
  font-size: 12px;
  color: #78909c;
  margin: 10px 0;
  line-height: 2;
}
._2kpa7OD4hjAEaleE0XfvBR {
  width: 500px;
}
._24ZVYa_Nwrdfqv-Jqn59bC {
  font-size: 25px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._1fEc3LOaKsO1ssDAX_Cel {
  display: flex;
  font-weight: lighter;
}
.GeLOHp-doYUcUhWEhneNS {
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: #00b8ab;
  margin-right: 10px;
}
._2kDmJlNOzIOINZ_N1bOcB9 {
  padding: 0 5px;
  font-size: 16px;
  color: #78909c;
}
._20_O04yZtE-_7Q3upbN6bO {
  float: left;
}
