.zgFLuuhSiL1lVpZRB8orW {
  width: 500px;
  overflow: visible;
}
._1KtiwouWIqyO4w9ANvpVNW {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3geNrzr_Xt08bOJecrAT4c {
  display: flex;
  color: #91a4af;
  align-items: baseline;
}
._2c3UeiF7UEBvinTSC5-Nu3 {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  margin: 10px 0 30px;
}
._2c3UeiF7UEBvinTSC5-Nu3 > div:first-of-type {
  flex: 1;
  margin-right: 10px;
}
._2c3UeiF7UEBvinTSC5-Nu3 > div:first-of-type ul {
  border-radius: 5px;
  overflow: auto;
  margin-top: 5px;
}
._1q1dBYj4dRh0A-vx3XkSuZ {
  display: flex;
  justify-content: space-between;
}
