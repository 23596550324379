._3CT1w7iBOOwhmVQT5Wp1F- {
  position: fixed;
  bottom: 0;
  top: 60px;
  height: calc(100% - 50px);
  margin-top: -10px;
  background-color: #313c42;
  width: 280px;
  display: block;
  border-top-right-radius: 10px;
  box-shadow: 3px 0px 3px rgba(0,0,0,0.1);
  box-sizing: border-box;
  color: #fff;
  overflow-x: hidden;
  transition: all 0.3s;
}
._1_02X3OQh_mwU9og1krWfS {
  width: 48px;
}
._370vhbOPwgu73zhiD5Ruec {
  padding: 20px;
  padding-top: 40px;
  display: flex;
  position: sticky;
  top: 0;
  background: #313c42;
  z-index: 1;
}
._2k-beEcp2bL9-9iB1yj-- {
  padding: 20px 3px;
}
._19paVOftlLDuhuQOX9pBGT {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
._38nKFmslPFqN9V6y8BNP3N {
  color: #72828a;
  font-size: 13px;
}
._2CZTUed7UhXa76_P4lAE1x {
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._198fUOx0BNQLVkAKauCTNf {
  color: #90a4ae;
  height: 40px;
  width: 40px;
}
._1uRuhFvM2yXwhPqSv8WJHO {
  composed: headerButton;
  color: #fff;
}
._2xFWCBe2b4pSDIl_PkX5_h {
  flex: 1;
}
._3QNB-RN6aH1-9KSwsFaLlK {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
._28ikp2FFlgknTuWUty4VX9 {
  transform: rotate(90deg);
  padding: 0 5px 5px 5px !important;
}
._1_Vr3hGrt_5iT64yd3isWX {
  transform: rotate(-90deg);
  padding: 10px 0 5px 0 !important;
}
