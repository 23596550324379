._1OFYTdKGv2r3JMCmoTP7by {
  min-width: 500px;
  overflow: visible;
}
._7O0KxRqVZ461KR_LvH2w9 {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3ScaIsfbqI1tNj_NGh-xXY {
  padding: 0;
}
.nYPfwmhOny_Mt6kLANJ4L {
  padding: 1px 10px;
  background: #f5f6f7;
}
._1zajPB8wcsLp2V76s21Sx1 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eceff1;
}
._1zajPB8wcsLp2V76s21Sx1:hover {
  background: #eceff1;
}
.Ovo_JeWIu_AvRFp3jHQUH {
  cursor: pointer;
  border-radius: 100%;
  padding: 3px;
  font-size: 20px;
}
._1zajPB8wcsLp2V76s21Sx1:hover .Ovo_JeWIu_AvRFp3jHQUH {
  background: #fff;
}
._2s-X-Inx4Mnumye4TqlRld {
  padding: 5px;
}
._1e__8dhEyFkZGIBgREuObB {
  flex: 1;
  font-size: 12px;
  padding: 3px;
  height: 30px;
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #546e7a;
  box-sizing: border-box;
}
._1e__8dhEyFkZGIBgREuObB + ._1e__8dhEyFkZGIBgREuObB {
  margin-right: 5px;
}
.M6NtlkoVNb1Fqr842xzLu {
  flex-basis: 45px;
  max-width: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
._3xbRDGQf0ykaBFkg1NUv_q {
  border: 2px solid #b0bec5;
  max-height: 210px;
  overflow-y: auto;
}
.cXjWZXpzq_KR1NQcrEKI_ {
  margin: 5px 10px;
}
