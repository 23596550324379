.questionnaireEditTableHeader {
  font-size: 13px;
  color: #546e7a;
  padding: 0 15px;
  margin-top: 10px;
}
.questionnaireEditTableHeaderText {
  font-size: 13px;
  color: #546e7a;
  padding-left: 10px;
}
.questionnaireEditTableHeaderText:before {
  top: 2px;
  left: 0;
}
.questionnaireEditTableHeaderText:after {
  top: 7px;
  left: 3px;
}
.questionnaireEditTableContainer {
  padding: 10px 15px;
}
.questionnaireEditTableContainer.unchecked {
  opacity: 0.4;
  background: #f0f0f0;
}
.questionnaireEditTableWrapper {
  border: 1px solid #cfd8dc;
  padding: 3px;
  margin: 12px auto;
}
.questionnaireEditTable {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 400;
  background: #fff;
  color: #78909c;
  border: 1px solid #cfd8dc;
}
