.WtDnOIxpVloxEdwz3CkdZ {
  display: flex;
}
._2uIr7nulh8Cyp-UQV7IS9A {
  min-width: 100px !important;
}
._1hIsTzA_ikSWkemoLXZ905 {
  cursor: pointer;
  border-radius: 100%;
  padding: 3px;
}
._1-AxNBOe2BMyiljJjKaIsv:hover ._28IGKVNCpIRJvniZBznSly > div > :first-child {
  display: none;
}
._1-AxNBOe2BMyiljJjKaIsv:hover ._28IGKVNCpIRJvniZBznSly > div > :last-child {
  display: block;
}
._1-AxNBOe2BMyiljJjKaIsv:hover ._1hIsTzA_ikSWkemoLXZ905 {
  background: #fff;
}
._28IGKVNCpIRJvniZBznSly {
  padding: 0;
  overflow: visible !important;
}
._1H8pHdNbjhUdjIB6oMa-UJ > div {
  display: flex;
  align-items: center;
}
._1H8pHdNbjhUdjIB6oMa-UJ i {
  margin-left: 5px;
  height: 18px;
  width: 18px;
  font-size: 18px;
}
._1H8pHdNbjhUdjIB6oMa-UJ > span {
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
}
