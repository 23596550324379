.rbCheckboxOptionButton {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  line-height: 14px;
  font-size: 10px;
  margin-right: 1px;
  padding: 1px 5px 1px 1px;
  text-transform: uppercase;
  font-weight: 400;
  color: #78909c;
}
.rbCheckboxOptionButton:focus {
  border-color: #546e7a;
  color: #546e7a;
}
.rbCheckboxOptionButton.readonly {
  cursor: default;
}
.rbCheckboxOptionButton.readonly:hover {
  border-color: transparent;
  color: #78909c;
}
.rbCheckboxOptionIcon.material-icons {
  font-size: 14px;
  height: 14px;
  line-height: inherit;
  vertical-align: middle;
}
.rbCheckboxOptionText {
  vertical-align: middle;
}
