._2U03mZ50OuhR5vlPXthJjZ {
  width: 500px;
  overflow: visible;
}
._1pEv3tjDKPyU0StYqi_Kxo {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._1KeFwx7CpYpPEwnRnajHhL {
  display: flex;
  align-items: center;
  position: relative;
}
._3MgHU6ax0kLmSUJNmmxB5t {
  position: absolute;
  right: 0px;
  padding: 3px;
  top: -20px;
  background: rgba(86,110,124,0.5);
  border-radius: 3px;
  color: #6a7a83;
}
._3V5dQlNmf7p7Ro5fDk48yf {
  flex: 1;
}
._3V5dQlNmf7p7Ro5fDk48yf input {
  overflow: hidden;
  text-overflow: ellipsis;
}
._2eCsSR5NbwCA2qSYsdlyyt {
  padding: 0 10px 10px;
  font-weight: 600;
  color: #556e7b;
  cursor: pointer;
}
.FX5c1RD-0aB5JwGrmDB9g {
  border-radius: 50px;
  display: flex;
}
._3Ej3NgITP4wDwYyApe0Td2 {
  flex: 1;
}
._3Ej3NgITP4wDwYyApe0Td2+._3Ej3NgITP4wDwYyApe0Td2 {
  margin-left: 5px;
}
._3bj4qadqe3kmSq5xiAbRT4 {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
}
._3bj4qadqe3kmSq5xiAbRT4 > span {
  display: flex;
}
._3bj4qadqe3kmSq5xiAbRT4 i {
  color: #90a4ae;
  padding-left: 10px;
  font-size: 20px;
}
._1mMpSgOFhiJvBVEe_W3Uec {
  justify-content: flex-end;
  color: #90a4ae;
  font-weight: bolder;
}
._1mMpSgOFhiJvBVEe_W3Uec i {
  padding: 0;
}
._3a97BiAjFJmegys6cUi_u5 {
  color: #556e7b;
  font-size: 13px;
  margin-bottom: 10px;
}
._2L_g9sWH2Dor5DBAR50oH8 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
._2L_g9sWH2Dor5DBAR50oH8 i {
  font-size: 35px;
  color: #00b8ab;
}
._2L_g9sWH2Dor5DBAR50oH8 span {
  font-size: 20px;
  font-weight: lighter;
  color: #556e7b;
}
