.rbvCheckBox {
  background: transparent;
  border: 0;
  color: #546e7a;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  transition: none;
  line-height: 1;
}
.rbvCheckBox:focus {
  outline: 1px solid #00b8ab;
}
.rbvCheckBox.error {
  outline: 1px solid #f15a25;
}
