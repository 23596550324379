.agent-invitation-handler {
  height: 100%;
  width: 100%;
  position: relative;
}
.agent-invitation-handler .content {
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.1);
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  color: #546e7a;
  display: flex;
}
.agent-invitation-handler .content .flex-center {
  flex: 1;
}
