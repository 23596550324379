.questionnaireQuestion {
  flex: 8 0 120px;
  padding: 3px 3px 2px;
  font-size: 10px;
  font-weight: 400;
  color: #78909c;
  min-width: 0;
  min-height: 43px;
  border: 1px solid #eceff1;
  margin: 2px;
}
.questionnaireQuestion.question-size-1 {
  flex: 10 0 80px;
}
.questionnaireQuestion.question-size-2 {
  flex: 8 0 120px;
}
.questionnaireQuestion.question-size-3 {
  flex: 7 0 160px;
}
.questionnaireQuestion.question-size-4 {
  flex: 6 0 200px;
}
.questionnaireQuestion.required {
  background-color: #fefcd3;
}
.questionnaireQuestionLabel {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 12px;
  margin: 2px 0;
}
.questionnaireQuestionLabelOrd {
  padding: 0 2px 0 3px;
  border-radius: 3px;
}
.questionnaireQuestionInput {
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  width: 100%;
  height: 17px;
  padding: 0 3px;
  font-size: 11px;
  line-height: 15px;
  background: #fff;
  color: #546e7a;
  transition: all 0.1s linear;
}
