.questionnaireEditQuestion {
  padding: 3px 3px 2px;
  font-size: 10px;
  font-weight: 400;
  color: #78909c;
  min-width: 0;
  min-height: 43px;
  box-sizing: border-box;
  border: 1px solid #cfd8dc;
  margin: 2px;
  background: #fff;
  flex: 17 1 17%;
  cursor: pointer;
}
.questionnaireEditQuestion.question-size-1 {
  flex: 10 1 10%;
}
.questionnaireEditQuestion.question-size-2 {
  flex: 17 1 17%;
}
.questionnaireEditQuestion.question-size-3 {
  flex: 25 1 25%;
}
.questionnaireEditQuestion.question-size-4 {
  flex: 33 1 33%;
}
.questionnaireEditQuestion.question-type-list {
  flex: 1 1 auto;
}
.questionnaireEditQuestion.question-type-list .questionnaireEditQuestionLabel {
  max-width: 200px;
}
.questionnaireEditQuestion.required {
  background-color: #fefcd3;
}
.questionnaireEditQuestion.removed {
  background-color: #eceff1;
  color: #b0bec5;
}
.questionnaireEditQuestion.removed .questionnaireEditQuestionInput {
  border-color: #cfd8dc !important;
  background: #cfd8dc !important;
}
.questionnaireEditQuestion.removed .questionnaireEditQuestionListCheckbox:before {
  border-color: #cfd8dc !important;
  background: #cfd8dc !important;
}
.questionnaireEditQuestion.locked,
.questionnaireEditQuestion.locked .questionnaireEditQuestionListCheckbox {
  cursor: not-allowed !important;
}
.questionnaireEditQuestion.preview,
.questionnaireEditQuestion.preview .questionnaireEditQuestionListCheckbox {
  cursor: default !important;
}
.questionnaireEditQuestionLabel {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 12px;
  margin: 2px 0;
  cursor: inherit;
}
.questionnaireEditQuestionLabelOrd {
  padding: 0 2px 0 3px;
  border-radius: 3px;
  background: transparent;
}
.questionnaireEditQuestionLabelOrd.locked {
  background: #546e7a;
  color: #fff;
}
.questionnaireEditQuestionInput {
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  width: 100%;
  height: 17px;
  padding: 0 3px;
  font-size: 11px;
  line-height: 15px;
  background: #fff;
  color: #b0bec5;
  cursor: inherit;
}
.questionnaireEditQuestionListCheckbox {
  cursor: inherit !important;
}
