._2zEcSSyMoLIb02kIpxvqzM {
  display: flex;
}
._2_VMqpwNVcu4B5eDmUPZeO {
  white-space: nowrap;
  padding: 0 3px 0 16px;
  border-radius: 2px 0 0 2px;
  text-align: left;
  line-height: 2.5 !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 200px;
}
._2_VMqpwNVcu4B5eDmUPZeO:hover {
  position: relative;
  z-index: 1;
  border-color: #00b8ab;
}
._2_VMqpwNVcu4B5eDmUPZeO:focus {
  border-color: #00b8ab;
}
.IZ2gXSluDJKjYmRIjA_wG {
  padding: 0 5px;
}
.IZ2gXSluDJKjYmRIjA_wG .rbvDropDown {
  right: 0 !important;
  top: 100% !important;
}
._2_VMqpwNVcu4B5eDmUPZeO i {
  line-height: 1.7 !important;
}
._19RSVpZmR8NU5-DLIHGki9 {
  width: calc(100% - 15px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._5tOo2oDxKJ5aAnywxnmY8 {
  color: #78909c;
}
._3raDxDIjTkuGmMHPRXd3hk {
  transform: rotateX(180deg);
}
._3lcPdg5hE_qlAd12PFc2x {
  transform: rotateX(180deg);
  color: #00b8ab !important;
}
._1YkglW_ZytcYXSFjXLfwei {
  background: #fff;
  border: 1px solid #cfd8dc;
  margin-top: 4px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.18);
}
