._3Lu5REOOFDKCPQOx9p43ya {
  overflow: auto;
  will-change: transform;
  -webkit-overflow-scrolling: touch;
}
._1_5kv-g0dwlKlQ_ay-NRVZ {
  position: relative;
  display: flex;
  width: 100%;
}
