.RbVLFS_Viewport {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
}
.RbVLFS_Viewport .heading {
  display: block;
  position: sticky;
  top: 0;
  z-index: 9;
}
.RbVLFS_Viewport .contents {
  display: block;
}
.RbVLFS_Viewport .listRow {
  display: block;
  overflow: hidden;
}
.RbVLFS_Viewport .footer {
  display: block;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1;
}
