._3L4fxm5XYx0Opt2K2LyGO9 {
  width: 450px;
  overflow: visible;
}
._3dgy1mNPstZXETSNjDZ5Zr {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
._2c4sFu0f6SSYar1uLS3Q0Z {
  display: flex;
  align-items: center;
}
._2c4sFu0f6SSYar1uLS3Q0Z i {
  font-size: 45px;
  color: #00b4a6;
  margin-right: 10px;
}
._2c4sFu0f6SSYar1uLS3Q0Z > ._3V67tZrnJdzJ8Bw8kv99SJ {
  font-size: 15px;
}
._2c4sFu0f6SSYar1uLS3Q0Z ._2IVTTA2dUSCrzseDTR5cLS {
  font-size: 17px;
  font-weight: normal;
}
