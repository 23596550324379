.xGk3uSvHUgo0i-qWZQq7n {
  width: 400px;
  overflow: visible;
  color: #78909c;
  border-radius: 1px solid;
}
._1Oh9qd_Ili2SHIn-CCY0f- {
  font-size: 25px;
  line-height: 14pt;
  font-weight: lighter;
}
._3dGt3SoQxUjYWd_YtqhMEN {
  font-size: 12px;
}
._3H4fKkN90XgC48b87w_WZi {
  display: flex;
  font-weight: lighter;
}
._3EgC8hqVfq8vvQfwSkZGpw {
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: #00b9aa;
  margin-right: 10px;
}
._1KuVOXxYs-sEJLdbt6UNI0 {
  padding: 5px;
  font-size: 18px;
  color: #78909c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Y07INjFgf1Uf_60GxQuA7 {
  float: left;
}
.h1oyzjAejVhwlauGSb7- {
  padding: 5px 0;
  border: 1px solid;
  margin: 5px 0;
  max-height: 190px;
  overflow: auto;
}
.h1oyzjAejVhwlauGSb7- ._34s0BcRFaXZtWKCbDdA0Xf {
  padding: 5px;
}
.h1oyzjAejVhwlauGSb7- ._34s0BcRFaXZtWKCbDdA0Xf:not(:last-child) {
  border-bottom: 1px solid;
}
