._3pHM-Hu91zGsDm3pnZCKzD {
  width: 450px;
  overflow: visible;
}
._2jWHyVCFhswsgDOzz4Ibax {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
._1MF4CXjbf7TIjK_PkY7Rbm {
  display: flex;
  align-items: center;
}
._1MF4CXjbf7TIjK_PkY7Rbm i {
  font-size: 45px;
  color: #00b4a6;
  margin-right: 10px;
}
._1MF4CXjbf7TIjK_PkY7Rbm > ._2l8fmRhe-uJoUVjaODIygI {
  font-size: 15px;
}
._1MF4CXjbf7TIjK_PkY7Rbm ._2G8GcFckOvf2_HpuyYivc6 {
  font-size: 17px;
  font-weight: normal;
}
