.rbv-ng-inactive-accounts {
  background: #f5f6f7 url(images/auth-background.b871974.png);
  background-size: cover;
  height: calc(100vh - 61px) !important;
  overflow: auto;
}
.rbv-ng-inactive-accounts .page {
  height: 100%;
  width: 100%;
  position: relative;
}
.rbv-ng-inactive-accounts .page .content {
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.1);
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  color: #546e7a;
  display: flex;
  width: 400px;
}
.rbv-ng-inactive-accounts .page .content .flex-center {
  flex: 1;
}
.rbv-ng-inactive-accounts .page .content .flex {
  width: 100%;
  display: flex;
  align-items: center;
}
.rbv-ng-inactive-accounts .page .content .flex.column {
  flex-direction: column;
  align-items: flex-start;
}
.rbv-ng-inactive-accounts .page .content .flex i.material-icons {
  font-size: 40px;
  margin-right: 10px;
  color: #049e92;
}
.rbv-ng-inactive-accounts .page .content .flex .title {
  font-size: 15px;
}
.rbv-ng-inactive-accounts .page .content .flex .sub-title {
  font-size: 20px;
}
.rbv-ng-inactive-accounts .page .content p {
  margin: 10px 0;
}
