._xLJJQwb6v1iEI0AAF0cF {
  min-width: 750px;
  overflow: auto;
}
._17mMVNhvETVdp62Oq4FQin {
  overflow: auto;
}
._2IUB0230uyq01-s1-lrKp4 {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3Vc-sT4KMPIc86NHOt5e_s {
  padding: 5px 0;
  font-size: 13px;
  color: #46555c;
  margin-bottom: 10px;
}
._2E7V0B8RHhEEkvM7KMZZRd {
  font-size: 11px;
  font-weight: normal;
}
.GgeN-UeJg7qEFpapoi0eH > div {
  height: 25px;
}
._3bjDo6-4zoEfIDKd9Gmj5G {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
}
._3PRC08AIXUGek3dT4RgUul {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}
._2e7Q-F5yJ21dhewCZEAAX1 {
  margin: 0 !important;
}
._2uB1wfCUvekvVJnuLW1Z-r {
  float: left;
}
._1Jb3eghi6PlBusJc0Kc9RR {
  margin-top: 1px;
  display: flex;
  align-items: center;
}
._3032wPo7YDW7IcauMhJBew .button {
  height: 25px !important;
  min-width: fit-content;
  font-size: 12px !important;
  cursor: pointer;
  padding: 2px 6px;
}
._3032wPo7YDW7IcauMhJBew {
  cursor: pointer;
  height: unset;
}
._3032wPo7YDW7IcauMhJBew .button i.material-icons {
  z-index: 9;
}
._3032wPo7YDW7IcauMhJBew .button i.material-icons:first-child {
  font-size: 18px;
}
._24luT7sx1TSHXTfpOVqJt6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._1W3BttLEUxIzjms3UJ7XXk {
  border: 1px solid #dddee0;
  max-height: 272px;
  overflow: auto;
  margin: 15px 0;
}
._1YXfyHmZPLCgLEOFnkv3Mw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 5px;
  border-bottom: 1px solid #dddee0;
  color: #a1a9ac;
}
._1YXfyHmZPLCgLEOFnkv3Mw > span {
  color: #7e96a2;
  font-size: 12px;
}
._1QWSW6BjaJVGS7mN8Tfpe0 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #46555c;
  margin-bottom: 20px;
}
._1QWSW6BjaJVGS7mN8Tfpe0 .rb-action-select {
  margin: 0 10px;
}
