._2UuYYqgdS21aDJxFJoIuMK {
  display: inline-block;
}
.xM-CMoDDU4w6e6HiUgwE2 {
  padding: 0 10px 0 24px;
}
._1q30fapdpWNxRZDwK5DkHW {
  color: #78909c;
}
.QY907EZgsJ7t1Emv5WrjF {
  transform: rotateX(180deg);
}
._317F6pjOEPEs3Aw5OrWeo9 {
  background: #fff;
  border: 1px solid #cfd8dc;
  margin-top: 4px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.18);
}
