._39LFbLOO9wkTZm1yWutro7 {
  width: 450px;
  overflow: visible;
}
._2uKf5fCNh2WeLffKQz_UsI {
  height: auto;
}
._3CKhqDlDhakZ9DbRV6wB_B {
  display: flex;
}
._3CKhqDlDhakZ9DbRV6wB_B .rbInputContainer {
  flex: 1;
}
._3CKhqDlDhakZ9DbRV6wB_B > div:last-child {
  margin-left: 10px;
}
