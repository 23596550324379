._3j_SC9FkEEm2luBeGISMiR {
  position: relative;
}
._1ed0Jn7v2kBipXyG9s_ooQ {
  height: calc(100vh - 60px);
  padding-left: 280px;
  transition: padding 0.3s;
  overflow: hidden;
}
.SumkfwgS9gZxG0rYYde_R {
  padding-left: 48px;
}
._1dZF3rvwGBXKyPVzqOAkq_ {
  padding: 100px;
}
