._1Ep9yTo_OsAzBmnB82rZ5- {
  display: block;
  height: 40px;
  line-height: 40px;
  background: #fbfbfc;
  border: 1px solid #eceff1;
  border-left: 0;
  border-right: 0;
  text-decoration: none !important;
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #546e7a !important;
}
._1ASA40di5qv2ClYQPJN0Ba {
  background: #00b8ab;
  border: 1px solid #00b8ab;
  color: #fff !important;
}
.GXfOVVO4zmIFLPm2oJkD2 {
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px;
  color: #546e7a;
}
._2eYYJZL2Q7HFWBr4TOq0Kc {
  color: #fff;
}
.bCjH21TMy1euAMkKyCJkx {
  vertical-align: middle;
  color: #546e7a;
}
._1CGKJDwle4-eILY7GrF3JO {
  color: #fff;
}
