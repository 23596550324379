._1RkqbccFqihIOiKZmnw878 {
  position: relative;
}
._3da5EIyQ3qI5JvHULXe8Ii {
  padding-right: 40px !important;
}
._2ePsK6bWkNlzpMhu5aqSJ0 {
  position: absolute;
  right: 20px;
  top: 0;
  height: 36px;
  line-height: 34px;
}
._3qlN_-7NEoa7rlgcSWZDYY {
  font-size: 20px;
  vertical-align: middle;
  color: #455a64;
}
._1Lp7dzJUK4lGyjMkgIEVM1 {
  transform: scale(1.5);
}
._359L-kcGONGMdNHxRP9nhL {
  background: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 3px;
  margin-top: -12px;
  box-shadow: 0 0 3px rgba(0,0,0,0.18);
}
.EJLD9tPtvnORr12Jx0tVV {
  border-bottom: 1px solid #eceff1;
  cursor: pointer;
  font-size: 12px;
  line-height: 32px;
  color: #546e7a;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.EJLD9tPtvnORr12Jx0tVV:hover {
  background-color: #b7e7e3;
}
._1vHUpP5n4w53t4A45BP8Wg {
  vertical-align: middle;
  font-size: 18px;
  color: #b0bec5;
  margin: 0 8px;
}
._2hK8FJcB9AquIHDgvNaozj {
  padding: 5px 15px 5px 5px;
  display: flex;
}
._7evyZgAwvdusabaxulEir {
  font-size: 12px;
  line-height: 36px;
  color: #90a4ae;
  flex: 1;
}
.ti3VfkZpDPGqNNCCNb841 {
  vertical-align: middle;
  font-size: 18px;
  margin: 0 8px;
}
._1Pu05T0dtsd6TO0rWCPNA_ {
}
._2DMqmAYqg4Ihks2LygaF-z {
}
._3h0ZmIOXCy4xt1xRue2zH_ {
}
