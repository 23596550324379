._1FuF5HfnsxjAUSTNQt908c {
  width: 620px;
}
._1SXWOYtMrKkiJRtvomTE1r {
  height: auto;
}
._9YjoNa_b_ES7rh0m2pAwp {
  display: flex;
}
._9YjoNa_b_ES7rh0m2pAwp .rbInputContainer {
  flex: 1;
}
._9YjoNa_b_ES7rh0m2pAwp > div:last-child {
  margin-left: 10px;
}
._1EdzhDp3dVN-nIYq_dDRAY {
  display: flex;
  align-items: center;
  color: #78909c;
  cursor: pointer;
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  justify-content: space-between;
}
._1Cs_6lujMccL7EKNfUZyUs {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._1zti4T4gbop_nxlKyZnHvM {
  max-height: 210px;
  overflow-y: auto;
  border: 1px solid #eceff1;
  color: #546e7a;
}
._3mO6PXhCJgarLgHpXFwr5E {
  padding: 10px;
  border-bottom: 1px solid #eceff1;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
._3mO6PXhCJgarLgHpXFwr5E ._3AQIg1YqqEF4QFodNuO46- {
  flex: 1;
}
._1FuF5HfnsxjAUSTNQt908c .rbCheckboxOptionButton {
  padding: 0;
  border: none !important;
  margin-right: 10px;
}
._1FuF5HfnsxjAUSTNQt908c .rbCheckboxOptionIcon.material-icons {
  font-size: 20px;
}
._1ti_eVU6ZTEBVYR4MaxrU {
  display: flex;
  align-items: center;
}
