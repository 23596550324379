.manage-accounts-row {
  padding: 10px 20px;
  border-bottom: 1px solid #eceff1;
  height: 100px;
  box-sizing: border-box;
}
.manage-accounts-row .details {
  flex: 1;
}
.manage-accounts-row .details .top {
  font-size: 12px;
  align-items: center;
  color: #8a999f;
}
.manage-accounts-row .details .top > * {
  margin-right: 5px;
}
.manage-accounts-row .details .top i.material-icons {
  background: #00b8aa;
  color: #fff;
  border-radius: 100%;
  padding: 3px;
  font-size: 14px;
}
.manage-accounts-row .details .top i.material-icons.admin {
  background: #f25b26;
}
.manage-accounts-row .details .top .position {
  text-transform: uppercase;
}
.manage-accounts-row .details .top.unassigned {
  font-size: 13px;
}
.manage-accounts-row .details .top.unassigned i.material-icons {
  background: unset;
  color: #f25b26;
  padding: 3px;
  font-size: 14px;
}
.manage-accounts-row .details.unverified,
.manage-accounts-row .details.pending {
  color: #8a999f !important;
}
.manage-accounts-row .details.unverified i.material-icons,
.manage-accounts-row .details.pending i.material-icons {
  background: unset;
  color: #8a999f;
  padding: 3px;
  font-size: 17px;
}
.manage-accounts-row .details span.dot {
  font-size: 15px;
  font-weight: bolder;
}
.manage-accounts-row .details .name {
  font-size: 16px;
}
.manage-accounts-row .details .contact {
  font-size: 12px;
  color: #8d999f;
}
.manage-accounts-row .details .contact > * {
  margin-right: 5px;
}
.manage-accounts-row .actions {
  justify-content: flex-end;
}
.manage-accounts-row .actions .button i.material-icons.active {
  color: #00b8aa;
}
.manage-accounts-row .actions .rfp-manager-select {
  margin-left: 5px;
}
