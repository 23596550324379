._2-E657TWPxZSeENvPLa22R {
  background: #fff;
  border: 1px solid #cfd8dc;
  margin-top: 4px;
  border-radius: 3px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.18);
  max-height: 70vh;
  overflow: auto;
}
.HSbhlCgz3vFeTv490Qiub {
  background: #eceff1;
  padding: 5px;
  font-size: 14px;
}
