._1TQjC8-cy6va7MjbDaWIwo {
  padding: 20px;
}
.YkvaHOl2_LVIazVG57yke {
  line-height: 23px;
}
._3-t5HZ6dpQCQr0rP9sLQPH {
  color: #00b8ab;
  font-size: 18px;
  vertical-align: middle;
}
._1LZ1xQSJCJnqferzAAcEIz {
  font-size: 12px;
  color: #90a4ae;
  vertical-align: middle;
}
._1sJ4AYvZTwkODl-HfN42Ay {
  font-size: 20px;
  color: #263238;
  font-weight: normal;
}
.nn7qmSzZXAy1ntsVBwjqc {
  padding: 0 20px;
  height: 41px;
  display: flex;
  align-items: center;
}
._15jo7uLbXDAIPQlZnprr9g {
  margin-right: 10px;
  color: #00b8ab;
}
._3OGkO360sc0xnZaeAxC2h {
  flex: 1 0 auto;
  font-weight: bold;
  font-size: 14px;
  color: #37474f;
}
._1aRmC9Lv4s0lwe0nwL_pXv {
  padding: 26px 20px;
  border-top: 1px solid #eceff1;
}
._3I78swcXXSP43rOj_xTTdc {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 9px;
  align-items: center;
}
._1nPCGuB0CGFX5Ln3YfeDM1 {
  flex: 0 0 130px;
  padding-right: 20px;
  color: #78909c;
}
._8xfjNNS-FCkAXiv-wW4-z {
  flex: 1 0 300px;
  color: #455a64;
  overflow-x: auto;
}
.uwcWiQZTUK77QZqWEXGT4 {
  flex: 0 0 35px;
  text-align: right;
}
