._1AIEmGDDLrCXckmXiHCoGd {
  width: 550px;
}
._3uXXrXzgogRGAhvnpTvbY5 {
  font-size: 13px;
  color: #546e7a;
  line-height: 16px;
  text-align: left;
  margin-bottom: 16px;
}
._3Lk5q-VX5VKUTOJhc5Jpmy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._1yal0r-qN6ELi19aS9HbhJ {
  display: flex;
  font-size: 13px;
  color: #546e7a;
  align-items: center;
}
