._2a2CRyxTeuCbuhvc96vWv6 {
  padding: 0 50px;
}
._1-hKgXORzwWDRTPP20Lkxl {
  padding: 0 10px;
  background: rgba(0,0,0,0.5);
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
}
._2jlU3w9PXu9AFQOtOsAZN9 {
  padding-left: 1px;
}
._1-hKgXORzwWDRTPP20Lkxl > div {
  height: 20px;
  overflow: hidden;
}
._2upjtClpqYS80RsnWtAA4S {
  width: fit-content;
  margin: 5px 0;
}
