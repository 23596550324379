._29iJMGF4mdo2G2CMSbn80l {
  flex: 0 0 50px;
  line-height: 58px;
  text-align: center;
}
._2fXPIZyXRhGt7MMHp87mfc {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  display: inline-block;
}
._1Z0vLY-1kBomsg_FkiQSVC {
  color: #00a99d;
}
._2mQkTUIbbukibWBDC1oL1f {
  color: #546e7a;
}
._1_O8yLMrHsn2cCPfHpR_qm {
  color: #b0bec5;
}
._1I_VPKMujM4uIz9Xwf9e7n {
  max-width: 240px;
  text-align: left;
}
