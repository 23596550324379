._2GezHToqBPiilrqY9r_GOl {
  height: 36px;
  line-height: 30px;
  min-width: 90px;
  padding: 0 15px 0 0;
  border-radius: 18px;
  font-size: 14px;
  cursor: pointer;
  transition: all 100ms linear;
}
._2GezHToqBPiilrqY9r_GOl[disabled] {
  background: #eceff1;
  border: 1px solid #cfd8dc;
  color: #b0bec5;
  cursor: not-allowed;
}
._2GezHToqBPiilrqY9r_GOl[disabled]:hover {
  background: #eceff1;
  border: 1px solid #cfd8dc;
  color: #b0bec5;
}
._3GC7d2eGX4_vqSY-dl7yrR {
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #546e7a;
}
._3GC7d2eGX4_vqSY-dl7yrR:hover {
  background: #fff;
  border: 1px solid #dcdee0;
  color: #455a64;
}
._3HCGYkIx1qsfiBwElaVvG1 {
  background: #00b8ab;
  border: 1px solid #00a99d;
  color: #fff;
}
._3HCGYkIx1qsfiBwElaVvG1:hover {
  background: #00a99d;
  border: 1px solid #00a99d;
  color: #fff;
}
._2y-OkYomNPzi4TSVm5tSE4 {
  background: #f15a24;
  border: 1px solid #e65622;
  color: #fff;
}
._2y-OkYomNPzi4TSVm5tSE4:hover {
  background: #e65622;
  border: 1px solid #e65622;
  color: #fff;
}
.m8bhG7NyTYdkDg7ZI4kAj {
  vertical-align: middle;
  width: 20px;
  font-size: 24px;
  margin: 0 10px;
}
._1X9QRU6sejr5wgIpHzQKso {
  vertical-align: middle;
}
