.questionnaireEditModuleHeader {
  height: 27px;
  background: #455a64;
  margin: 8px 0 1px;
  padding: 0 15px;
}
.questionnaireEditModuleHeaderText.rbCheckboxLabel {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 26px;
  color: #fff;
  vertical-align: middle;
  padding: 0 4px 0 16px;
}
.questionnaireEditModuleHeaderText.rbCheckboxLabel:before {
  width: 14px;
  height: 14px;
  top: 5px;
  border-radius: 2px;
}
.questionnaireEditModuleHeaderText.rbCheckboxLabel:after {
  width: 9px;
  top: 11px;
  left: 3px;
  border-color: #455a64;
}
.questionnaireEditModuleHeaderText.rbCheckboxLabel.preview {
  cursor: default;
}
.questionnaireEditModuleHeaderText.rbCheckboxLabel.preview:before,
.questionnaireEditModuleHeaderText.rbCheckboxLabel.preview:after {
  display: none;
}
