._1u9dwLYc85c-yaUUS6o2iZ p {
  font-size: 12px;
  color: #78909c;
  margin: 10px 0;
  line-height: 2;
}
._1u9dwLYc85c-yaUUS6o2iZ {
  width: 450px;
}
._2AS0CvBbTtFMKn2IutDjTf {
  font-size: 25px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
.mcHQMicnXwjpgYt4usOWh {
  display: flex;
  font-weight: lighter;
}
._399yHEPcg2Q-S7r4sgTcY2 {
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: #00b8ab;
  margin-right: 10px;
}
._1_yFZ5UuYGBM-fKKGyahJn {
  padding: 0 5px;
  font-size: 16px;
  color: #78909c;
}
.-BlzD3SBBbJtJAUdAbtn1 {
  display: flex;
  justify-content: space-between;
}
