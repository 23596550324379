.questionnaireTableContainer {
  padding: 0 15px;
}
.questionnaireTableHeaderText {
  font-size: 13px;
  color: #546e7a;
  padding-left: 10px;
  margin-bottom: 20px;
}
.questionnaireTableActionContainer {
  margin: 5px 0 5px 20px;
}
.questionnaireTableActionLabel {
  width: 80px;
  display: inline-block;
  font-size: 11px;
  color: #546e7a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.questionnaireTableActionButton {
  color: #fff;
  background-color: #00a99d;
  border: solid 1px #00a99d;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 1;
  margin: 0;
  padding: 2px;
  height: 20px;
  width: 20px;
}
.questionnaireTableActionButton .material-icons {
  font-size: 14px;
}
.questionnaireTableActionButton:disabled {
  cursor: default;
  background-color: #eceff1 !important;
  color: #90a4ae !important;
  border: 1px solid #dcdee0 !important;
}
.questionnaireTableActionButton:hover {
  background-color: rgba(0,169,157,0.8);
  color: #fff;
  border-color: #00a99d;
}
.questionnaireTableWrapper {
  border: 1px solid #cfd8dc;
  padding: 3px;
  margin: 12px auto;
}
.questionnaireTable {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 400;
  background: #fff;
  color: #78909c;
  border: 1px solid #cfd8dc;
}
.questionnaireTable.w-100 {
  width: 100%;
}
.questionnaireTableWrapper.m-0 {
  margin: 0;
}
.questionnaireTableWrapper.bg-white {
  background: #fff;
}
.questionnaireTableRow {
  height: 27px;
}
.questionnaireTableRow.row-header {
  background-color: #eceff1;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.questionnaireTableRow.row-sub-header {
  background-color: #fdfdfe;
}
.questionnaireTableCellQuestion,
.questionnaireTableCellLabel {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  padding: 0;
}
.questionnaireTableCellLabel {
  padding: 0 5px;
}
.questionnaireTableCellLabel.strong {
  text-transform: uppercase;
  font-weight: bold;
}
.questionnaireTableRow.row-header .questionnaireTableCellLabel {
  background-color: #eceff1;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.questionnaireTableRow.row-sub-header .questionnaireTableCellLabel {
  background-color: #fdfdfe;
}
