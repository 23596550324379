._2HdEfNuLdUuPVHWGztI_Ne {
  width: 100%;
  height: 85px;
  background-color: #263238;
  display: flex;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  align-items: flex-end;
  padding-left: 10px;
}
._1xHRNj2sQH8FZg2YywXOp3 {
  overflow: hidden;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  border-radius: 6px !important;
}
._3RISyHAXlP_SniGLd8Ofjy {
  border: 3px solid #2db8aa !important;
}
._2zyyd2RGovEjontmqaImzq {
  height: 70px;
  flex: 100%;
  display: flex;
  margin-top: 15px;
}
._125_EMyEgr56CrQ_ikWXck {
  height: 70px;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 70px;
  flex: 0;
  margin: 0 2.5px;
  border-radius: 10px !important;
  overflow: hidden;
  margin-top: 15px;
}
._125_EMyEgr56CrQ_ikWXck:last-child {
  margin-right: 280px;
}
._1LNvA90C6Vy11hrKWC7a-I {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
._1z7s78NBoomOe7mln1HMcU i {
  margin: 0 !important;
}
._1z7s78NBoomOe7mln1HMcU:hover i {
  margin: 0 10px 0 -5px !important;
}
._1z7s78NBoomOe7mln1HMcU span {
  display: none;
}
._1z7s78NBoomOe7mln1HMcU:hover span {
  display: inline;
}
._3UE49aVAlfQr6-G85neWEn {
  position: fixed;
  right: 0;
  bottom: 80px;
  border-radius: 0 !important;
  border-top-left-radius: 18px !important;
}
._1r62mXclaksaaLzGDpqIF2 {
  overflow-x: auto;
  overflow-y: hidden;
}
._1r62mXclaksaaLzGDpqIF2,
._1r62mXclaksaaLzGDpqIF2 ._2zyyd2RGovEjontmqaImzq {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
/* IE 9 */
  -webkit-transform: rotateX(180deg);
}
