._2kbKiZRCJPODAS2P8Hd4I4 {
  width: 500px;
}
._3eYsHPTo4RlJBIOhAXbpev {
  display: flex;
}
._3eYsHPTo4RlJBIOhAXbpev ._3qCOssRYFalM1PBbmizDrq {
  height: 64px;
  width: 64px;
  object-fit: contain;
  border-radius: 3px;
  margin-right: 10px;
  border: 1px solid #eceff1;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
._3eYsHPTo4RlJBIOhAXbpev ._3qCOssRYFalM1PBbmizDrq i.material-icons {
  font-size: 50px;
}
._2x2nJZi9lKS7REkKHxfLEw {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
._2x2nJZi9lKS7REkKHxfLEw ._1ar1zvngavAn22C0vyMxpF {
  font-size: 15px;
}
._218tS_VZRS3zGEN6hZiwAi {
  font-size: 12px;
  font-weight: normal;
  color: #546e7a;
}
._2x2nJZi9lKS7REkKHxfLEw ._218tS_VZRS3zGEN6hZiwAi .material-icons.active {
  color: #00b8aa;
  font-size: 18px;
  margin-right: 0 5px;
}
._36Ok1fS8GPwQna8_F5l5tg {
  display: flex;
  align-items: center;
}
._19fEf8j4bWb1-sYmkYDl0M {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  color: #546e7a;
  font-size: 12px;
}
._31W_RX4OAmUqWQ8Wnfuet1 {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #b0bec5;
  color: #546e7a;
}
._3LAM0pKSXTP6NJVLm4LRe3 {
  padding: 10px 5px;
  height: 42px;
  border-bottom: 1px solid #eceff1;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
}
._3LAM0pKSXTP6NJVLm4LRe3 ._1ar1zvngavAn22C0vyMxpF {
  flex: 1;
}
._2kbKiZRCJPODAS2P8Hd4I4 .rbCheckboxOptionButton {
  padding: 0;
  border: none !important;
}
._2kbKiZRCJPODAS2P8Hd4I4 .rbCheckboxOptionIcon.material-icons {
  font-size: 20px;
}
._2NIKqflYK9XIknKViIZjkw {
  border-bottom: 3px solid #cfd8dc;
}
._306r3krDLhbXSADRAZu1uA {
  display: flex;
  font-size: 12px;
  color: #7f909b;
  margin: 10px 0;
}
._306r3krDLhbXSADRAZu1uA i {
  font-size: 30px;
  margin-right: 10px;
}
.s-R9xMkacLjLnnaRsXs5N {
  display: flex;
  color: #f25b26;
  align-items: center;
}
.s-R9xMkacLjLnnaRsXs5N i {
  font-size: 14px;
}
