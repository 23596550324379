.vNotificationWrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;
  min-height: fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.vNotificationWrapper + .vNotificationWrapper {
  background: rgba(0,0,0,0.05);
}
.vModal {
  transition: all 0.2s ease-in-out;
}
.vNotification-enter,
.vNotification-leave-to {
  opacity: 0;
}
.vNotification-enter-to,
.vNotification-leave {
  opacity: 1;
}
.vNotification-enter .vModal,
.vNotification-leave-to .vModal {
  transform: scale(1.1);
}
.snackbar {
  background: #00a99d;
}
