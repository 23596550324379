.rbvSelect {
  position: relative;
  display: block;
}
.rbvSelect .control {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1001;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  border: 1px solid #dcdee0;
  overflow: hidden;
  font-size: 13px;
  color: #455a64;
  cursor: text;
  outline: none;
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
}
.rbvSelect .control:hover {
  box-shadow: 0 1px 0 rgba(0,0,0,0.06);
}
.rbvSelect .control.focused {
  border-color: #00b8ab;
}
.rbvSelect .valueContainer {
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 10px;
  flex: 1;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}
.rbvSelect .selectPlaceholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #aaa;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.rbvSelect .selectValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.rbvSelect .selectInput {
  font-family: 'Noto Sans', sans-serif;
  box-sizing: border-box;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  outline: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  vertical-align: middle;
}
.rbvSelect .clearContainer {
  cursor: pointer;
  text-align: center;
  user-select: none;
  padding-right: 2px;
  width: 25px;
  height: 24px;
  vertical-align: middle;
  position: relative;
  z-index: 1001;
}
.rbvSelect .arrowContainer {
  cursor: pointer;
  text-align: center;
  user-select: none;
  padding-right: 5px;
  width: 25px;
  height: 24px;
  vertical-align: middle;
  position: relative;
  z-index: 1001;
}
.rbvSelect .overlayContainer {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
}
.rbvSelect .overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
}
.rbvSelect .optionsContainer {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0,0,0,0.06);
}
.rbvSelect .optionsScrollContainer {
  margin-bottom: 1px;
  display: block;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.rbvSelect .option,
.rbvSelect .noOptions {
  font-size: 13px;
  color: #455a64;
  background-color: #fff;
  display: block;
  padding: 8px 10px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbvSelect .option.selected,
.rbvSelect .noOptions.selected {
  background: rgba(0,169,157,0.2);
}
.rbvSelect .option.marked,
.rbvSelect .noOptions.marked {
  background: rgba(0,169,157,0.6);
  color: #fff;
}
.rbvSelect .noOptions {
  color: #ccc;
  cursor: default;
}
.rbvSelectFade-enter-active,
.rbvSelectFade-leave-active {
  transition: opacity 0.3s;
}
.rbvSelectFade-enter,
.rbvSelectFade-leave-to {
  opacity: 0;
}
