.LocationsCustomMessageEditor {
  min-width: 920px;
}
.LocationsCustomMessageEditor .Body {
  margin-top: 20px;
  min-height: 300px;
  height: 620px;
  width: 920px;
}
.LocationsCustomMessageEditor .Loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
