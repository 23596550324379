._2DVNNZXhHHu73UWlhPehpt {
  min-width: 500px;
  overflow: visible;
}
._18XcRYt7sS9b3mC9w5Y4eD {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3lPqU9zM4QCdbi2D8gzu0d {
  border: 1px solid #6d838c;
  margin: 15px 10px;
  padding: 2px !important;
}
