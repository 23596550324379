._2o-jS-vXYWzuKiu5EPwbA4 {
  height: 36px;
  line-height: 30px;
  min-width: 90px;
  padding: 0 15px;
  border-radius: 18px;
  font-size: 14px;
  cursor: pointer;
  transition: all 100ms linear;
}
._2o-jS-vXYWzuKiu5EPwbA4[disabled] {
  background: #eceff1;
  border: 1px solid #cfd8dc;
  color: #b0bec5;
  cursor: not-allowed;
}
._2o-jS-vXYWzuKiu5EPwbA4[disabled]:hover {
  background: #eceff1;
  border: 1px solid #cfd8dc;
  color: #b0bec5;
}
._3zLkkb2xJuVJI3sTJHyc0L {
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #546e7a;
}
._3zLkkb2xJuVJI3sTJHyc0L:hover {
  background: #fff;
  border: 1px solid #dcdee0;
  color: #455a64;
}
._1vbsNR0bF74W7PEUwUQLzp {
  background: #00b8ab;
  border: 1px solid #00a99d;
  color: #fff;
}
._1vbsNR0bF74W7PEUwUQLzp:hover {
  background: #00a99d;
  border: 1px solid #00a99d;
  color: #fff;
}
._2xAmiqVxPx1ybsEVvnhhvv {
  background: #f15a24;
  border: 1px solid #e65622;
  color: #fff;
}
._2xAmiqVxPx1ybsEVvnhhvv:hover {
  background: #e65622;
  border: 1px solid #e65622;
  color: #fff;
}
