._31NG6QMIrWIJz3-ODso7cK {
  font-weight: bold;
  font-size: 14px;
  color: #37474f;
}
._1Gzx_eUPCVoIFXyqA4yy8l {
  font-size: 12px;
  color: #78909c;
  margin: 5px 0;
}
._1z7dRgeNR8ZiXn-FATINFC {
  width: 500px;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid rgba(84,111,122,0.31);
  color: #37474f;
}
._1Fx25QjLpxNsGRG7eGXMBy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(84,111,122,0.31);
  cursor: pointer;
  font-size: 16px;
}
._1AEpDvAw7m-NnzD_y4pOTh {
  max-height: 0vh;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
._1AEpDvAw7m-NnzD_y4pOTh._1iqlBUeb6WI_QMJuVeomcU {
  max-height: 100vh;
  overflow: auto;
}
.QsnNTSB0okq5viFoBC9VV {
  font-size: 12px;
  gap: 10px;
}
.QsnNTSB0okq5viFoBC9VV ._26C-NotAS2S_4sLfztYh6E {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 2px solid #cbd2d5;
}
.QsnNTSB0okq5viFoBC9VV ._1Gzx_eUPCVoIFXyqA4yy8l {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.QsnNTSB0okq5viFoBC9VV ._21Y3piK_QMeRWtTQlFQzmu i {
  color: #00b8ab;
}
._1oK-z8wRGaO6r3lD4o8As {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
._1oK-z8wRGaO6r3lD4o8As > div:first-child {
  color: #37474f;
  font-size: 16px;
}
._1oK-z8wRGaO6r3lD4o8As button {
  display: flex;
  align-items: center;
}
