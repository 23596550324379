._1EdaundcIYpyy1dCOevOJ_ {
  width: 450px;
  overflow: visible;
}
._3o-huLLomRYVwDgiYIKdw8 {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
._1-yGC1mOQHFnoeYfqZmrF6 {
  display: flex;
  align-items: center;
}
._1-yGC1mOQHFnoeYfqZmrF6 i {
  font-size: 45px;
  color: #00b4a6;
  margin-right: 10px;
}
._1-yGC1mOQHFnoeYfqZmrF6 > ._1LDec89Xs4yh3NwA5giYw5 {
  font-size: 15px;
}
._1-yGC1mOQHFnoeYfqZmrF6 .UyYLWmClyze4B9ja5Usl7 {
  font-size: 17px;
  font-weight: normal;
}
