.questionnaireEditGroupHeader {
  background: #90a4ae;
  height: 22px;
  margin: 0;
  padding: 0 15px;
  line-height: 19px;
}
.questionnaireEditGroupHeaderText {
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
  padding: 0 4px 0 24px;
}
.questionnaireEditGroupHeaderText.rbCheckboxLabel {
  padding: 0 4px 0 16px;
}
.questionnaireEditGroupHeaderText.rbCheckboxLabel:before {
  width: 14px;
  height: 14px;
  top: 5px;
  border-radius: 2px;
}
.questionnaireEditGroupHeaderText.rbCheckboxLabel:after {
  width: 9px;
  top: 11px;
  left: 3px;
  border-color: #90a4ae;
}
.questionnaireEditGroupQuestions {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px 15px;
}
.questionnaireEditGroupQuestions:after {
  content: '';
  flex: 100000 0 1%;
}
