._1EODW301og2fsTKbJlxGHj {
  min-width: 490px;
  width: auto;
}
._5uxarD6HYTsPlzMJpxrZ5 {
  color: #78909c;
  font-size: 12px;
  max-width: 100%;
  font-weight: 400;
}
.QSrn0Nqeq0HtWWS9n-Btc {
  flex: 0 1 auto;
  margin: 10px 0 5px;
  border-bottom: 1px solid #eceff1;
  border-top: 1px solid #eceff1;
}
._1qCuzi6BSTLPotcxAD-VIb {
  flex: 0;
  margin: 30px 0 20px;
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
}
._3Ns8KmY8mgfNIGcySR-c1Y {
  flex: 0 0 auto;
  margin-right: auto;
}
