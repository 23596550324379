.account-manage-companies {
  color: #37474f;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 100px);
}
.account-manage-companies .loader {
  height: 100%;
}
.account-manage-companies .header {
  padding: 20px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.account-manage-companies .header > div > i.material-icons {
  padding: 10px;
  background: rgba(0,0,0,0.1);
  border-radius: 100%;
  margin-right: 10px;
}
.account-manage-companies .header .title {
  font-size: 12px;
  color: #8c999f;
}
.account-manage-companies .header .sub-title {
  font-size: 20px;
}
.account-manage-companies .flex {
  display: flex;
}
.account-manage-companies .flex.row {
  align-items: center;
  justify-content: flex-start;
}
.account-manage-companies .flex.column {
  flex-direction: column;
}
.account-manage-companies .controls {
  margin-top: 10px;
  justify-content: space-between !important;
}
.account-manage-companies .controls,
.account-manage-companies .controls > div {
  align-items: flex-end !important;
}
.account-manage-companies .controls .rfp-manager-group-by {
  font-size: 12px;
}
.account-manage-companies .controls > .row {
  flex: 1;
}
.account-manage-companies .button {
  padding: 2px 5px;
  align-items: center;
  display: flex;
  font-size: 12px;
  background: #fff linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  height: 27px;
}
.account-manage-companies .button:not(.icon-only) i.material-icons {
  margin: 0 5px 0 0;
  font-size: 18px;
}
.account-manage-companies .create {
  background: #00b8ab linear-gradient(0deg, #00b0a3 10%, #00b8ab 11.05%, #00b8ab 100%);
  color: #fff;
  border: 1px solid #00b8a9;
}
.account-manage-companies .logo {
  position: relative;
  margin-right: 10px;
}
.account-manage-companies .logo > img,
.account-manage-companies .logo > div {
  height: 65px;
  width: 110px;
  object-fit: contain;
  border-radius: 3px;
  border: 1px solid #eceff1;
  background: #eceff1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-manage-companies .logo > img i.material-icons,
.account-manage-companies .logo > div i.material-icons {
  font-size: 50px;
}
.account-manage-companies .logo > img {
  background: transparent;
}
