._3AK2ezzwMxgt4pEtBxCt-R {
  flex: 1;
}
._2QNGfYSJwPKaBpvBRrUO6S {
  position: relative;
  background-color: #313c41;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
._31HXepGaJU-ylCo4WhpcOO {
  background-color: transparent;
  border: 0;
  resize: none;
  min-height: 100%;
  max-height: 200px;
  width: 100%;
  color: #fff;
  font-size: 13px;
  padding: 10px 0 0 60px;
  box-sizing: border-box;
}
._2EHy9wXqmrlMTGcIYi7j-t {
  position: absolute;
  top: 50%;
  color: #2db8aa;
  transform: translateY(-50%);
  background: #263238;
  padding: 2px;
  font-size: 11px;
  border-radius: 10px;
  left: 10px;
}
._3BD8W26kLGOKoZBmewb1Fq {
  color: #d75539 !important;
}
._15ZTI9OkYjt4-W0m9PLg5n,
._3QoV-Z6_VFRsdYywNxJEZo {
  cursor: pointer;
  color: #90a2ae;
}
._24uJlGza90r-v_MPRvE7D6 {
  color: #fff;
}
