.rbv-ng-invitations-handler {
  background: #f5f6f7 url(images/auth-background.b871974.png);
  background-size: cover;
  height: calc(100vh - 61px) !important;
  overflow: auto;
}
body.invitation .rbOverlay .rb-dialog {
  box-shadow: none;
  margin-top: 65px;
}
body.invitation .rbOverlay .rb-dialog .close.standalone {
  display: none;
}
