._3-CnU9QDXXylvS__amaNuX {
  min-width: 500px;
  overflow: visible;
}
._2IXtzsBs43JlXY6woJ-s1g {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
