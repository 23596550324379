._1sNHf1V_30H7cVsnrFGVhw {
  width: 100%;
  color: #b0bec5;
}
.Ov0SkTbuOL9k_0oq9KcMn {
  height: 48px;
}
._3uvwIXuDP4PgiCFWgPk4Ys ._1sa6VI8cPEhRiYQC_IwF5c {
  background-color: #00b8ab;
  color: #fff !important;
}
._3uvwIXuDP4PgiCFWgPk4Ys {
  background-color: #263238;
}
._2fTBEyLLwBzNCIprXVEtso ._1sa6VI8cPEhRiYQC_IwF5c {
  background-color: #00b8ab;
  color: #fff !important;
}
._1sa6VI8cPEhRiYQC_IwF5c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 15px;
  box-sizing: border-box;
  cursor: pointer;
}
._1sa6VI8cPEhRiYQC_IwF5c:hover {
  background: #263238;
}
.wL3SvDKRndM9XW7YeaiCq {
  flex: 1;
  padding-left: 15px;
  font-size: 13px;
  line-height: 25px;
}
._10CX88Ot4uAk_7PgTwAvB7 {
  padding: 4px 15px;
}
