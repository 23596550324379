._1wxldQZ3uDnhDV_95DvwtC {
  padding: 20px;
}
._1wgGeszkNlLdLxqOll7UT3 {
  line-height: 23px;
}
._1my_BR8ia2IlI8DmFXzkWS {
  color: #00b8ab;
  font-size: 18px;
  vertical-align: middle;
}
._3-BSLyhykF9H_HQnwqx_Au {
  font-size: 12px;
  color: #90a4ae;
  vertical-align: middle;
}
.C-MIlCUgAfHkSe0QUHkt0 {
  font-size: 20px;
  color: #263238;
  font-weight: normal;
}
._1mjc_q5mlR-U68e9bLABhN {
  padding: 0 20px;
  height: 41px;
  display: flex;
  align-items: center;
}
._2V1OJkK8P7wTjH654OYA44 {
  margin-right: 10px;
  color: #00b8ab;
}
._3OjsRFEJGGbUoGWodb_c4y {
  flex: 1 0 auto;
  font-weight: bold;
  font-size: 14px;
  color: #37474f;
}
._293j5ygs1bKxE1WWatpBJS {
  padding: 26px 20px;
  border-top: 1px solid #eceff1;
}
._25wxQoDf1hiGgd8EEHIZmM {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 9px;
}
._2l220JNatMP-EMBpcV3kiI {
  flex: 0 0 130px;
  padding-right: 20px;
  color: #78909c;
}
.pkhw3YmTqMMXVb_p2nKE {
  flex: 1 0 300px;
  color: #455a64;
  overflow-x: auto;
}
._364CRT9xM6eN4vUk9aA1Yp {
  color: inherit !important;
  font-size: inherit !important;
}
._364CRT9xM6eN4vUk9aA1Yp:hover {
  color: #37474f !important;
  text-decoration: none;
}
