.questionnaireTableCellInput {
  width: 75px;
  min-width: 100%;
  height: 26px;
  background: #fff;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 26px;
  padding: 0 3px;
  color: #546e7a;
  font-family: "Noto Sans";
}
.questionnaireTableCellInput::-webkit-input-placeholder {
  color: #b0bec5;
}
.questionnaireTableCellInput:-moz-placeholder {
  color: #b0bec5;
}
.questionnaireTableCellInput:-ms-input-placeholder {
  color: #b0bec5;
}
.questionnaireTableCellInputContainer {
  padding: 3px;
}
.questionnaireTableCellInputContainer.required {
  background-color: #fefcd3;
}
.questionnaireTableCellInputContainer.error {
  background-color: #f15a24;
}
