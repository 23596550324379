.travelDestinationsMapSuppliersFilterInputWrapper {
  position: relative;
  display: inline-block;
  flex: 1;
}
.travelDestinationsMapSuppliersFilterInputWrapper:before {
  position: absolute;
  top: 7px;
  left: 10px;
  font-family: "Material Icons";
  color: #546e7a;
  font-size: 24px;
  content: '\E8B6';
}
.travelDestinationsMapSuppliersFilterInput {
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  width: 100%;
  border: 1px solid #dcdee0;
  padding: 0 10px 0 40px;
  display: block;
  background: #fff;
  border-radius: 2px;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  color: #263238;
  border-radius: 20px;
}
.travelDestinationsMapSuppliersFilterInput:focus {
  border-color: #00b8ab;
}
.travelDestinationsMapSuppliersFilterInput:placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
.travelDestinationsMapSuppliersFilterInput::-moz-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
.travelDestinationsMapSuppliersFilterInput::-webkit-input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
.travelDestinationsMapSuppliersFilterInput:-ms-input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
.travelDestinationsMapSuppliersFilterInput:input-placeholder {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  line-height: 36px;
  color: #b0bec5;
}
