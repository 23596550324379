.questionnaireTableContainer {
  padding: 0 15px;
}
.questionnaireTableWrapper {
  border: 1px solid #cfd8dc;
  padding: 3px;
  margin: 12px auto;
}
.questionnaireTable {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 400;
  background: #fff;
  color: #78909c;
  border: 1px solid #cfd8dc;
}
