a.tdMapChainFilterListBrand {
  display: block;
  height: 33px;
  line-height: 33px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f4f5;
  padding-left: 35px;
  font-size: 12px;
  color: #90a4ae;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.tdMapChainFilterListBrand .material-icons {
  font-size: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
a.tdMapChainFilterListBrand.selected {
  color: #455a64;
}
