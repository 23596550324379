._2cn_q-dCoV-hN7gCUl9Ob0 {
  min-width: 730px;
  overflow: visible;
}
._12IuWXWl72lhFSaC7iu4DV {
  overflow: auto;
}
._8AlSIlhgL1BNNZqNMH6h4 {
  display: flex;
  justify-content: space-between;
}
._2lYsA_axgQ_rzmQ_raznC4 {
  width: 48.73%;
}
