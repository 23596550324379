._24_s53_QZfZ6WYmh3vNCHE {
  width: 750px;
}
._1rPLK8X4Mv-FRkDSIONDxk {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;
}
._1mU4ohjGTm-7qOK6g2GPEA {
  flex: 0 0 48px;
  height: 48px;
  margin-right: 20px;
}
._2PzihY2nCFfAB3J7SP2UpF {
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
}
.e27r4MeV2cC8uSmQGR0qi {
  height: 18px;
  font-size: 17px;
  text-transform: uppercase;
}
._33p7poX-lG8SHDji3iDMKz {
  vertical-align: text-top;
}
._1t1PirvwZhA2zfZhBTRCcm {
  height: 30px;
  font-size: 28px;
}
.ResyQAzyLIky-6xi3NZ2F {
  vertical-align: text-bottom;
}
._1_cO6FFM_Oe2h8rWHD0c9p {
  flex: 0 1 auto;
  padding: 0;
  margin: 10px 0 5px;
  display: flex;
  flex-direction: column;
}
._39xmwv8zOuOh439jRa2A_L {
  padding: 0 20px;
  font-size: 14px;
  line-height: 28px;
  color: #546e7a;
  flex: 0 0 auto;
  margin-bottom: 25px;
}
._32RnkfzGA3vCD7a-SEmKr7 {
  flex: 1 0 300px;
  overflow-y: auto;
  position: relative;
}
._34YJQ7lqb7EOivFi66GUQ6 {
  height: 41px;
  display: flex;
  border-bottom: 1px solid #eceff1;
  align-items: center;
  position: sticky;
  font-size: 12px;
  color: #263238;
}
._3zvtmmLtF1Btw6lou3dJt7 {
  flex: 1 3 auto;
  min-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 20px;
}
._2G2_5Jk2XiUhHJvjeJanEq {
  flex: 3 1 auto;
  min-width: 200px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
}
._3dtu3sAW-IalW7qHRiEazw {
  width: 126px;
  margin: 0 10px;
}
