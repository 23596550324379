._22SGw-I-bhUHe0iE9xGdeW {
  margin-left: auto !important;
}
._3etNA5IbsKAFNepDWcVoo3 {
  width: 450px;
}
._7NrLTRuMUpG5YfefK4x3u {
  color: #546e7a;
  font-size: 12px;
  line-height: 32px;
  font-weight: bold;
}
._2OpSFRU_p6v4Ldz81vUhHM {
  overflow: auto;
  max-height: 220px;
  min-height: 150px;
}
._1o33F_5pR_4ElcSH71c9V3 {
  display: grid;
  grid-template-columns: [label] 1fr [value] 0.6fr [up] 30px [down] 30px [delete] 30px;
  grid-column-gap: 5px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 5px 0.6fr 5px 30px 5px 30px 5px 30px;
}
._3D-x_33TE81RRoNZXCCldx {
  height: 25px;
  line-height: 25px;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #546e7a;
  font-size: 12px;
  background: #eceff1;
  border-bottom: 1px solid #d9e0e3;
}
._2aH22iMC8j4Ml-LFUG9h1f {
  height: 27px;
  margin: 5px 0;
}
._3YO8AJ64NYGqqgOv_mzlsY {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 27px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #d9e0e3;
  padding: 0 5px;
  color: #78909c;
  font-size: 12px;
  font-family: "Noto Sans", sans-serif;
}
._3YO8AJ64NYGqqgOv_mzlsY:focus {
  border-color: rgba(0,184,171,0.8);
  color: #00b8ab;
}
._2E8YMd37EdYD-8FyM7DOqj {
  border-color: rgba(241,90,36,0.8);
  color: #f15a24;
}
._2E8YMd37EdYD-8FyM7DOqj:focus {
  border-color: rgba(241,90,36,0.8);
  color: #f15a24;
}
