.UB6WQFzoqPcESv1Vct_t6 {
  height: 41px;
  display: flex;
  border-bottom: 1px solid #eceff1;
  align-items: center;
  font-size: 13px;
  color: #546e7a;
}
._1syqIDtoTYvRLFuZDVauv- {
  background: #fff;
}
._3XcGSMm21Qq6m8su8c3WNx {
  background: #f5f6f7;
}
._2yGoOl9braTV_FfplNuJeJ {
  flex: 1 3 auto;
  min-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 20px;
}
.i_y_hrtDQ-4v0lLS2sVel {
  flex: 3 1 auto;
  min-width: 200px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.diOvuEwkZvJWJSdwQirss {
  flex: 1 0 auto;
  line-height: 20px;
  margin-right: 10px;
}
._2c_t3Bztlk1zumVVSyByez {
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
  font-weight: normal;
}
._30qN7du36hX7nSB8flM1tm {
  line-height: 12px;
  font-size: 11px;
  color: #90a4ae;
  font-weight: normal;
}
._3Yvd_BgfCdpP0W51RpzPCm {
  flex: 0 0 auto;
  font-size: 26px;
  height: 26px;
}
.FZyL5caC4VrHbS9hZ62vD {
  color: #00b8ab;
}
._2yJfHVGGvoBczEamXRq267 {
  color: #78909c;
}
