.hotel-directory-preview-bar {
  height: 90px;
  flex-wrap: nowrap !important;
  padding: 25px 50px !important;
}
.hotel-directory-preview-bar .controls {
  display: flex;
  align-items: center;
}
.hotel-directory-preview-bar .controls.right {
  justify-content: flex-end;
}
