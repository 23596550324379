._2ZF3c_KZ0QuCWdzQS5XJsu {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  line-height: 14px;
  font-size: 10px;
  margin-right: 1px;
  padding: 1px 5px 1px 1px;
  font-weight: 400;
  color: #78909c;
}
._2ZF3c_KZ0QuCWdzQS5XJsu._3WxdrggNENlw-Vp-Nbf3IG {
  cursor: default;
}
._2ZF3c_KZ0QuCWdzQS5XJsu._3WxdrggNENlw-Vp-Nbf3IG:hover {
  border-color: transparent;
  color: #78909c;
}
._2ZF3c_KZ0QuCWdzQS5XJsu._2T-JvlhapKHZEHrw-FjanK {
  cursor: default;
  color: #546e7a;
}
._2ZF3c_KZ0QuCWdzQS5XJsu._2T-JvlhapKHZEHrw-FjanK:hover {
  border-color: transparent;
  color: #546e7a;
}
._3Wi21kM7T9lWWtzxOSWBl {
  font-size: 14px;
  height: 14px;
  line-height: inherit;
  vertical-align: middle;
}
._1dnCSCIIN8zl_fPnDKa6jy {
  vertical-align: middle;
}
