.hotel-directory-amenities {
  display: inline-flex;
  flex-direction: row !important;
}
.hotel-directory-amenities .label {
  padding: 0 0 5px 0;
}
.hotel-directory-amenities .icon {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex-direction: column;
  padding: 0 3px;
  justify-content: space-evenly;
  height: 100%;
  color: #cfd8dc !important;
}
.hotel-directory-amenities .icon.active {
  color: #546e7a !important;
}
.hotel-directory-amenities .icon i {
  width: 24px;
}
.hotel-directory-amenities.expanded {
  flex-direction: column !important;
  flex: 1;
}
.hotel-directory-amenities .amenity {
  padding: 5px 25px 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #eceff1;
}
.hotel-directory-amenities .amenity .figure {
  padding-right: 5px;
  width: 25px;
}
.hotel-directory-amenities .amenity .label {
  flex: 1;
}
.hotel-directory-amenities .amenity.active .check {
  color: #58b8a7;
  font-weight: 900;
}
