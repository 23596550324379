.rbv-croppie .target {
  position: relative;
}
.rbv-croppie .target .cr-boundary {
  margin-bottom: 5px;
}
.rbv-croppie .target .cr-slider-wrap {
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 1;
  bottom: -4px;
}
.rbv-croppie .target .cr-slider-wrap .cr-slider {
  width: 100%;
  margin: 0;
  padding: 0;
}
.rbv-croppie .target .cr-slider-wrap .cr-slider::-webkit-slider-thumb {
  border: 1px solid #fff;
  background: rgba(255,255,255,0.99);
  box-shadow: 1px 1px 1px #000, 0px 0px 1px #0d0d0d;
}
.rbv-croppie .target .cr-slider-wrap .cr-slider::-ms-track {
  background: #16272b;
  opacity: 1;
}
