.questionnaireQuestion.list {
  flex: 1 1 auto;
}
.questionnaireQuestionLabel.list {
  max-width: 200px;
}
.questionnaireQuestionOptions {
  display: block;
  line-height: 14px;
  white-space: nowrap;
}
.questionnaireQuestionOption {
  display: inline-block;
  position: relative;
  line-height: 14px;
  margin-top: 2px;
}
.questionnaireQuestionOptionText {
  display: inline-block;
  cursor: pointer;
  margin: 0 8px !important;
  padding: 0 4px 0 8px !important;
  text-transform: uppercase;
  font-size: 10px;
  vertical-align: middle;
}
.questionnaireQuestionOptionText:before {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  top: 2px;
  left: 0;
  background: #eee;
  border: 1px solid #ddd;
}
.questionnaireQuestionOptionText:after {
  opacity: 0.1;
  content: '';
  position: absolute;
  width: 7px;
  height: 3px;
  background: transparent;
  top: 5px;
  left: 2px;
  border: 2px solid #546e7a;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.questionnaireQuestionOptionText.on:after {
  opacity: 1;
}
