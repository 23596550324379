._2TfPBvtKXi1-f2VIXLov-p {
  overflow: visible;
  width: 500px;
}
._28i0QGONQsjjkbnkAmu_M7 {
  display: flex;
}
._28i0QGONQsjjkbnkAmu_M7 ._2p6Dp2fuBp_UGyjdI3lfNB {
  height: 64px;
  width: 64px;
  object-fit: contain;
  border-radius: 3px;
  margin-right: 10px;
  border: 1px solid #eceff1;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
._28i0QGONQsjjkbnkAmu_M7 ._2p6Dp2fuBp_UGyjdI3lfNB i.material-icons {
  font-size: 50px;
}
._1u1Y0RqVPZxUsrQNrFXjh1 {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
._1u1Y0RqVPZxUsrQNrFXjh1 ._2NP6WHwdahCFarJO8HUmVY {
  font-size: 15px;
}
._3V4OB3FAoc74L_cTvFmk0v {
  font-size: 12px;
  font-weight: normal;
  color: #78909c;
  display: flex;
  align-items: center;
}
._23fmT0hwGEHNcfHGj8VGDu {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #cfd8dc;
  color: #90a4ae;
}
._3tZhfQixrJdkavgXUV4Z9B {
  padding: 10px 5px;
  height: 42px;
  border-bottom: 1px solid #cfd8dc;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
}
._3tZhfQixrJdkavgXUV4Z9B ._2NP6WHwdahCFarJO8HUmVY {
  flex: 1;
}
._3tZhfQixrJdkavgXUV4Z9B ._1djedIf-hTSaj8_N5xUE7A i.material-icons {
  font-size: 18px;
  height: unset;
}
._3tZhfQixrJdkavgXUV4Z9B._2MjP4J2mYGsEHmc4v2XhWn {
  color: #37474f;
}
