._3ERYp9Q7l6uNuzX3mCEMOD {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._3RMktDFd3H2gDLHEvpZ0zu {
  color: #90a4ae;
  margin-right: 5px;
}
.epqnkloZQ_ARJpi2-A4e {
  font-size: 12px;
  color: #263238;
}
._3uCcY0s-ZfaPbKdqhZGGva {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 7px;
  border-radius: 3px;
  background: #15a99d;
  color: #fff;
}
._2W42D2zhRTvXMnxqLSkdf_ {
  width: 100%;
}
._1L4oXEdZpxHAfeS2x8_WT7 {
  border: 1px solid #b0bec5;
  padding: 3px;
  transition: border-color 0.3s linear;
}
.bg3j2BNOvwVecvSyDfMJe {
  border-color: #f15a24;
}
.SHcEP15lVxRnrGERvIKOM {
  font-size: 11.667px;
  line-height: 20px;
  font-weight: normal;
  color: #546e7a;
}
