.E6vpJJP6BsRm7rw2kNQ1q {
  min-width: 400px;
}
._3f5TZGXocvagYDXZq0_2a4 {
  color: #546e7a;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}
.YZmUMw-D2ukrFHMjgxAUp {
  color: #546e7a;
  font-size: 11px;
  line-height: 13px;
}
