.rbCheckboxesContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px 0 5px;
}
.rbCheckboxesCheckbox {
  line-height: 13px;
}
.rbCheckboxesLabel {
  font-size: 11px;
  font-weight: 600;
  color: #78909c;
  cursor: pointer;
  margin: 0 10px;
}
.rbCheckboxesLabel:before {
  width: 14px;
  height: 14px;
}
.rbCheckboxesLabel:after {
  width: 8px;
  top: 6px;
}
