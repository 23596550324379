._1UQtsCRVYlcuNhX7d4MreG {
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
._2a1QpFn8x1XrwZrFEpDCsF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.zwA6R0pW0D_6mdYwEkPWP {
  padding: 0 10px;
}
._1jVv_cMTdDRah0lMijrVP6 {
  padding: 0 10px;
  font-size: 12px;
  color: #acbec5;
}
._2uRBFyHGAk_oW-_xeoxMUS {
  color: #2db8aa;
  background: #263238;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 5px;
  box-sizing: border-box;
}
._7v6vuxsKwPmJ1bZMqykoq {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  gap: 10px;
}
._3VnsUHdnd3Ctxa06bWbkxL {
  height: 36px;
  border: 1px solid #263238;
  display: inline;
  vertical-align: middle;
  margin: 0 10px 0 5px;
}
.dqF_QeQSoLUb2ZwYdub9I {
  overflow: visible;
}
._3a8t6h5Wiu72xdGvtvhry3 {
  height: calc(100vh - 149px);
  overflow: auto;
  box-sizing: border-box;
}
._2ezvvFzMs9j0Nc-1XaseE5 {
  display: table-row;
}
._1Fh3tQwQFcYPOi16sQbtn5 {
  box-sizing: border-box;
  vertical-align: bottom;
  padding: 0 10px 10px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 38px;
}
._1pPJEp1HEa2OBmi0-DLDyO {
  padding: 10px 0 5px 15px;
}
._1mTV6PAw1jrF_Bt_i0ASRb {
  background: #313c41;
  font-size: 12px;
  color: #acbec5;
  position: sticky;
  top: -1px;
  z-index: 1;
  border-bottom: 1px solid #d9e0e3;
}
._124jjLI3vVkMQ7IOXQxgtr {
  width: 100%;
  height: calc(100vh - 198px);
  background-color: #eceff1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #263238;
}
._124jjLI3vVkMQ7IOXQxgtr > * {
  margin: 5px 0;
}
.pelMwoZQte-E6S5ZmiuQV {
  color: #263238;
  font-size: 20px;
}
._3gTtoiXV_fMGlZYLKbg4AZ {
  color: #546e7a;
  font-size: 13px;
  max-width: 400px;
  margin: 0 auto;
}
._3Ugnwec69obC90eDV166pd {
  height: 30px;
  display: table-row;
}
._1BxPxh1ldnYalxXjxtNRD {
  padding: 5px 15px;
  color: #78909c;
  position: absolute;
}
._2ym4hxfVCZgx73i4VsZAjb {
  padding: 100px;
}
._24Zej2kcKZyvHPTSm8BxIW {
  overflow: visible !important;
  position: relative;
}
._1ep5HD-CiHI6le7LlBQlEM {
  position: unset !important;
  height: 25px !important;
}
._1ep5HD-CiHI6le7LlBQlEM > div > div > div {
  height: 25px !important;
  max-width: 120px !important;
  padding: 0 5px !important;
}
._1ep5HD-CiHI6le7LlBQlEM > div > div > div i:last-child {
  margin: 0 !important;
  padding: 0 10px 0 0 !important;
}
._1ep5HD-CiHI6le7LlBQlEM > div > div {
  padding: 0 5px !important;
  height: 25px !important;
}
._1ep5HD-CiHI6le7LlBQlEM i {
  padding: 5px !important;
}
._1ep5HD-CiHI6le7LlBQlEM i:last-child {
  padding: 0 5px !important;
}
._1orh09NAv2nfngiT6tAnNQ {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 300px;
  height: 180px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px 1px #78909c;
  border-radius: 3px;
  z-index: 9;
}
._1-G7pBosvPMAI6DuiIzu6 {
  display: flex;
  align-items: center;
  color: #546e7a;
  font-size: 17px;
  font-weight: lighter;
}
._1-G7pBosvPMAI6DuiIzu6 ._26lOpVJrKO8kZ0rS4M3H1L {
  color: #ec5c0b;
  font-size: 50px !important;
}
._3FeHkx9zGAHa9b-_7qVSra {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: unset !important;
  background-color: #f15a24;
  color: #fff !important;
  border: 0 !important;
}
._3S4snfElz7HbK8sKEOMi3w {
  color: #82909d;
}
._2jqqUc6hdFr6BJa_EyIxqg {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #82909d;
  cursor: pointer;
}
