.questionnaireTableRow {
  height: 27px;
}
.questionnaireTableRow.row-header {
  background-color: #eceff1;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.questionnaireTableRow.row-sub-header {
  background-color: #fdfdfe;
}
