.HoY2RifFMSgWaAcGRPCLr {
  display: flex;
  align-items: center;
}
.opsOcOk4I5vz1jY8dbvmg {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
._16qpCXs-NNhmUinyFDxuz7 {
  margin: 7px 0;
  flex: 1;
  max-width: 420px;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
