.hotel {
  border-radius: 5px;
  background: #fff;
  display: flex;
  position: relative;
  transition: all 0.3s;
  transition: height 0s;
  margin: 3px 0;
  height: 94px;
  min-width: 50vw;
}
.hotel.excluded {
  background: rgba(255,255,255,0.7);
}
.hotel .box {
  position: absolute;
  height: 18px;
  width: 18px;
  background: #fff;
  z-index: 2;
}
.hotel .box .rbCheckboxOptionButton {
  padding: 2px 0;
}
.hotel .box .rbv-loading {
  height: 18px;
  padding: 0;
}
.hotel .box .rbv-loading > div {
  padding: 0 2px;
}
.hotel .image {
  height: 92px;
  width: 90px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  object-fit: cover;
  margin: 1px;
  background: #fff;
}
.hotel .hotelClosed {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f15a24;
  color: #fff;
  line-height: 26px;
  margin-right: 1px;
  font-weight: bold;
  font-size: 16px;
}
.hotel .columns {
  flex: 1;
  min-width: 0;
  color: #323c43;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
}
.hotel .column {
  padding: 10px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: 1px solid #eceff1;
}
.hotel .column.main {
  width: 400px;
  flex: 1;
  min-width: 150px;
}
.hotel .column.amenities {
  width: 240px;
  min-width: 240px;
}
.hotel .column.phone {
  min-width: 130px;
  width: 130px;
}
.hotel .column .label {
  color: #90a4ae;
  text-transform: uppercase;
  font-size: 12px;
}
.hotel .column .hotel-name-container {
  display: flex;
}
.hotel .column .hotel-name {
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  color: #37474f;
}
.hotel .column .hotel-name.isClosed {
  color: #f15a24;
  text-decoration: line-through;
}
.hotel .column .sustainability {
  flex: 0 0 auto;
  padding-left: 10px;
  color: #008000;
}
.hotel .column .sustainability img {
  height: 20px;
  line-height: 20px;
  vertical-align: bottom;
}
.hotel .column .data {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #546e7a;
  font-size: 13px;
}
.hotel .column .data.hotel-type {
  justify-content: flex-start;
}
.hotel .column .data .address {
  padding: 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hotel:not(.open) {
  cursor: pointer;
}
.hotel:not(.open) .rate {
  position: relative;
}
.hotel:not(.open) .rate .value {
  color: #546e7a;
  font-size: 20px;
  font-weight: 600;
}
.hotel:not(.open) .rate .value.bestRate {
  font-size: 10px;
}
.hotel:not(.open) .rate .value.bestRate span {
  font-size: 20px;
}
.hotel:not(.open) .rate i {
  color: #90a4ae;
}
.hotel:not(.open) .rate .data {
  justify-content: flex-end;
}
.hotel:not(.open) .rate .dyn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 10px;
}
.hotel:not(.open) .column:not(.main) {
  width: 140px;
}
.hotel:not(.open) .column:not(.main) .label {
  text-align: center;
}
.hotel:not(.open) .column:not(.main) .data {
  justify-content: center;
}
.hotel:not(.open) .column:not(.main).rate {
  max-width: 212px;
  min-width: 212px;
  align-items: flex-end;
  padding: 10px;
}
.hotel:not(.open) .column:not(.main).distanceMi {
  max-width: 88px;
  min-width: 88px;
}
.hotel:not(.open) .column:not(.main).code {
  max-width: 150px;
  min-width: 150px;
}
@media screen and (max-width: 751px) {
.hotel:not(.open) {
    border-radius: 0 !important;
}
.hotel:not(.open) .image {
    border-radius: 0 !important;
}
.hotel:not(.open) .column:not(.sm) {
    display: none;
}
.hotel:not(.open) .rate {
    min-width: 0 !important;
    width: fit-content !important;
}
.hotel:not(.open) .rate .label {
    white-space: normal;
    text-align: right;
}
.hotel:not(.open) .main {
    min-width: 0;
}
}
.hotel.open {
  height: auto;
}
.hotel.open .image {
  height: 200px;
  width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  transition: height 0.3s;
}
.hotel.open .hotelClosed {
  line-height: 48px;
  font-size: 20px;
}
.hotel.open .columns {
  flex-direction: column;
  overflow: visible;
}
.hotel.open .columns .column {
  border-right: 0;
  white-space: normal;
  width: auto;
  padding-right: 25px;
}
.hotel.open .columns .column.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.hotel.open .columns .column.main .button i.material-icons {
  color: #e0161f;
}
.hotel.open .columns .column.main .hotel-name-container {
  display: block;
}
.hotel.open .columns .column.main .hotel-name {
  white-space: unset;
  display: inline;
}
.hotel.open .columns .column.main .sustainability {
  display: inline;
}
.hotel.open .columns .column:not(.main) {
  flex-direction: row;
  justify-content: space-between;
}
.hotel.open .columns .column.season-rates {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.hotel.open .columns .column.season-rates .season {
  min-width: 240px;
  flex: 1;
  margin: 5px;
}
.hotel.open .columns .column.season-rates .season .title {
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 5px;
}
.hotel.open .columns .column.season-rates .season .rates {
  border: 1px solid #eceff1;
  border-radius: 3px;
}
.hotel.open .columns .column.season-rates .season .rates .rate {
  border-bottom: 1px solid #eceff1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.hotel.open .columns .column.season-rates .season .rates .rate.best .label {
  color: #40b6a7;
}
.hotel.open .columns .column.season-rates .season .rates .rate .label {
  display: flex;
  align-items: center;
}
.hotel.open .columns .column.season-rates .season .rates .rate .data {
  font-size: 13px;
  font-weight: 600;
}
.hotel.open .columns .column.season-rates .season .rates .rate.best .data {
  font-size: 20px;
}
.hotel.open .columns .column.amenities.inline {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  padding: 5px 15px;
}
.hotel.open .columns .column.amenities.inline .amenity {
  padding: 5px 0;
  flex: unset;
  width: fit-content;
  border: none;
}
.hotel.open .columns .column.amenities.inline .amenity + .amenity {
  margin-left: 5px;
}
.hotel.open .columns .column.amenities.inline .amenity i.material-icons.check {
  font-size: 15px;
}
.hotel.open .columns .column.amenities.inline .amenity .label {
  padding: 0;
}
.hotel.open .columns .column.amenities.inline .amenity:not(.active) i.material-icons.check {
  color: #91a5af;
}
.hotel.open .columns .column .hotel-type .name {
  overflow: visible;
  white-space: normal;
}
.hotel.open .actions {
  position: absolute;
  right: 0;
  top: 5px;
}
.hotel.open .actions i {
  width: 30px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
}
.hotel.open .actions i + i {
  margin-left: 5px;
}
.hotel.open .actions .screen {
  display: none;
}
.hotel.open .logo {
  height: 60px;
  width: 100px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 501px) {
.hotel.open {
    display: block;
    border-radius: 0;
}
.hotel.open .data {
    flex-wrap: wrap;
}
.hotel.open .image {
    width: calc(100% - 2px);
    height: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 5px;
}
.hotel.open .image.expanded {
    height: 300px;
}
.hotel.open .logo {
    width: 120px;
    height: 80px;
}
.hotel.open .actions i {
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 3px;
    border-radius: 100%;
}
.hotel.open .actions .screen {
    display: inline-block;
}
.hotel.open .main {
    flex-direction: column;
}
}
.blackoutDates {
  color: #90a4ae;
  text-transform: uppercase;
  font-size: 12px;
  gap: 10px;
}
.blackoutDates > :first-child {
  flex: 1;
}
.notes {
  position: absolute;
  bottom: 2px;
  left: 108px;
  display: flex;
  gap: 1px;
  align-items: center;
  background: #fff;
  padding: 0;
}
.openedNotes {
  display: flex;
  position: relative;
  align-items: center;
  gap: 3px;
  padding: 0 16px;
}
