._1cbI22NC2KltsvQtF7FTom {
  width: 450px;
  overflow: visible;
}
.ZGZA-cLxq2MkW3RNZzxnI {
  font-size: 25px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._1qdxX4h4F6tbMMRioXaZDi {
  display: flex;
  font-weight: lighter;
}
._1DPoaaw0CfTrq2Xwr5YmNg {
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: #f15a24;
  margin-right: 10px;
}
._2YxGDInT68AHXEUdFPLAnp {
  padding: 5px;
  font-size: 14px;
  color: #78909c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._1JX-Wao_M8SxEY1tjrmv8t {
  background: #f15a24 !important;
  border: none !important;
}
._3z6S9G20G4gf2e2SRvmiK2 {
  font-size: 12px;
  color: #78909c;
}
