.rbvDropDownContainer {
  position: relative;
}
.rbvDropDownContainer .rbvDropDownToggler {
  position: relative;
  z-index: 5001;
}
.rbvDropDownContainer .rbvDropDown {
  position: absolute;
  z-index: 5002;
  min-width: 100%;
}
.rbvDropDownContainer .rbvDropDownRightAlign {
  right: 0;
}
.rbvDropDownContainer .rbvDropDownBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 5000;
}
.rbvDropDownContainer .rbvDropDownBackdrop.transparent {
  background: transparent;
}
