._12h5HMChIk57ERzbXdmJhY {
  padding: 26px 20px;
  border-top: 1px solid #eceff1;
}
._1dtz1Vp5L1f6_Xdv3kAxXW {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 9px;
}
.YKkWIWpqa0fsVRAASc5p0 {
  flex: 0 0 130px;
  padding-right: 20px;
  color: #78909c;
}
._1EdYtFvvzP32X93eB_s5kC {
  flex: 1 0 300px;
  color: #455a64;
  overflow-x: auto;
}
._2CMYaWllILG0-7-0RK2_Xf {
  color: inherit !important;
  font-size: inherit !important;
}
._2CMYaWllILG0-7-0RK2_Xf:hover {
  color: #37474f !important;
  text-decoration: none;
}
