.rbTooltip.simple.questionnaireTooltip {
  padding: 0;
}
.questionnaireTooltipBody {
  padding: 5px 10px 3px;
}
.questionnaireTooltipHint,
.questionnaireTooltipError {
  margin-top: 5px;
  font-size: 10px;
  text-align: left;
  background: #37474f;
  border-radius: 0 0 5px 5px;
  line-height: 20px;
}
.questionnaireTooltipError {
  background: #f15a24;
  line-height: 15px;
  padding: 5px 0;
}
.questionnaireTooltipHintText,
.questionnaireTooltipErrorText,
.questionnaireTooltipHintTextLocked,
.questionnaireTooltipHintTextRequired {
  color: #b0bec5;
  padding: 0 10px;
}
.questionnaireTooltipErrorText {
  color: #fff;
}
.questionnaireTooltipHintTextLocked {
  color: #00b3a6;
}
.questionnaireTooltipHintTextRequired {
  color: #fafe68;
}
.questionnaireEditTooltipHintAction {
  color: #eceff1;
  text-transform: uppercase;
  font-weight: 700;
}
