._24hNENv9klvP8t3h0SbyHc {
  min-height: 36px;
  box-sizing: border-box;
  color: #90a4ae;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
}
._24hNENv9klvP8t3h0SbyHc:hover {
  color: #fff;
}
.Lpnni013eevmF9MfoGpoV {
  color: #fff;
  flex: 1;
}
.azmr8uF7Zc6opk2HQI7Z8 {
  height: 24px;
  font-size: 20px;
}
.Lpnni013eevmF9MfoGpoV .azmr8uF7Zc6opk2HQI7Z8 {
  color: #ccc;
}
._1DalWQ2tVxiwyQU0uXX3Dy {
  font-size: 13px;
  padding-left: 15px;
  align-items: center;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
._1DalWQ2tVxiwyQU0uXX3Dy ._18xiDoeckcWZR_OA6_XFj2 {
  max-width: 150px;
}
.P8zvYpx3xPGSV5FxG9BSF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  color: #90a4ae;
}
.BegusnsTY6DmV4p_lDe6E {
  color: #fff;
}
._1k96zOZWuX_Zr1Vikwy_6g {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
._1ho97gs0_NsecCalgNiLms {
  flex-direction: column;
  align-items: flex-start;
}
._1AYcZLiz7lJBsu_-HCj6Br {
  padding: 5px !important;
  box-sizing: border-box;
  font-size: 13px;
  cursor: pointer;
  width: calc(100% - 50px);
  border-radius: 3px;
  margin: 5px 0 5px 50px;
  color: #677980;
}
._1AYcZLiz7lJBsu_-HCj6Br:hover {
  background: #313c42;
}
._1AYcZLiz7lJBsu_-HCj6Br .rbCheckboxOptionText {
  color: #677980 !important;
}
._3p-qLMoGHwbLJZefCtYgje {
  transform: scaleY(-1);
}
.DMXsrH8RN5UwMxWjmz2ys {
  transform: scaleX(-1);
}
