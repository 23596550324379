.zMnur9-MZvxehwbmLMz8- {
  padding: 0 10px;
  background: rgba(0,0,0,0.5);
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
}
.NN7i2R6xWfCQLUBX1_lJr {
  padding-left: 1px;
}
.zMnur9-MZvxehwbmLMz8- > div {
  height: 20px;
  overflow: hidden;
}
