._37336CZjGeOQ8wUBAEythM {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._375FJtfDUmMckN9DmL-ufZ {
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 5px;
  color: #546e7a;
}
