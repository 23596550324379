._7E1D2M6o3CFyVC6Iv8-OE {
  overflow: visible;
}
._3AMwS7dAeznllwOsZk0IOv {
  font-weight: bold;
  font-size: 14px;
  color: #37474f;
}
._1BdcBLQN7RxkRC-f_8BT0N {
  font-size: 12px;
  color: #78909c;
  margin: 5px 0;
}
.eDXzT3473R2-JZBt705jP {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}
.eDXzT3473R2-JZBt705jP._1vSzDDvVpZK7vp39HlX-6r {
  border: 2px solid #263238;
}
.ovXyMVtsJG-i27J9pX85e {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
._1O8P-UBmg2zqGG01K9XTYP {
  display: flex;
  gap: 6px;
  align-items: center;
  max-width: 300px;
  flex-wrap: wrap;
}
._2Vj9dC3fUCYuQZlOYBCnt8 {
  width: 500px;
}
._3sO1tdA108qfl_HiCrwA-U {
  width: 130px;
  height: 54px;
  min-height: 54px;
  border: 1px solid rgba(84,111,122,0.31);
}
.ovXyMVtsJG-i27J9pX85e > span {
  white-space: nowrap;
}
._2IEP5mk_9EjtPEWF3aJ0uJ {
  margin: 10px 0 5px;
}
._1QPDLbFluf0hq5b7RZ39xq {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  box-sizing: border-box;
  white-space: pre-wrap;
  display: block;
  min-height: 200px;
  width: 100%;
  border: 1px solid #cce;
  padding: 5px;
  border-radius: 5px;
}
._1QPDLbFluf0hq5b7RZ39xq::placeholder {
  color: #b0bec5;
}
