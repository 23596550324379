.vModalDefault {
  border-radius: 3px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  max-height: 95vh;
  max-width: 95vh;
  display: flex;
  flex-direction: column;
}
.vModalDefaultCloseButton {
  display: block;
  height: 36px;
  width: 36px;
  padding: 0;
  border: 0;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  font-family: Material Icons;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 1;
  background: transparent;
  font-size: 20px;
  color: #546e7a;
  transition: all 0.2s ease-in-out;
}
.vModalDefaultCloseButton:hover {
  color: #37474f;
}
.vModalDefaultHeader,
.vModalDefaultFooter {
  flex: 0 0 auto;
}
.vModalDefaultBody {
  flex: 0 1 auto;
  padding: 0 10px;
  margin: 10px 0 5px;
}
.vModalDefaultHeader {
  position: relative;
  padding: 14px 40px 0 10px;
  font-size: 14px;
  color: #37474f;
  font-weight: 600;
}
.vModalDefaultFooter {
  margin: 12px 0 14px;
  padding: 0 10px;
  text-align: right;
}
