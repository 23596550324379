.rbvChainsFilterMasterChain {
  display: block;
  box-sizing: border-box;
  height: 37px;
  line-height: 34px;
  width: 100%;
  padding: 0 10px;
  background: transparent;
  white-space: nowrap;
  cursor: pointer;
  color: #78909c !important;
  border-bottom: 1px solid #eceff1;
  border-top: 1px solid #eceff1;
  margin-bottom: -1px;
  font-size: 13px;
  text-decoration: none !important;
  transition: all 0.2s linear;
}
.rbvChainsFilterMasterChain .material-icons {
  vertical-align: middle;
}
.rbvChainsFilterMasterChain.expanded,
.rbvChainsFilterMasterChain.selected.expanded {
  background: #90a4ae;
  color: #fff !important;
  position: sticky;
  top: 0;
}
.rbvChainsFilterMasterChain.expanded .material-icons.arrow,
.rbvChainsFilterMasterChain.selected.expanded .material-icons.arrow {
  transform: rotateX(180deg);
}
.rbvChainsFilterMasterChain.someExpanded {
  background: #eceff1;
  border-color: #d7dee2;
}
.rbvChainsFilterMasterChain.selected {
  color: #455a64 !important;
}
.rbvChainsFilterMasterChainLabel {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 50px);
}
.tdMapBrandsShow-enter-active {
  transition: all 0.3s ease;
}
.tdMapBrandsShow-leave-active {
  max-height: 200px;
  transition: all 0.2s ease;
}
.tdMapBrandsShow-enter {
  transform: translateX(10px);
  opacity: 0;
}
.tdMapBrandsShow-leave-to {
  max-height: 0;
  transform: translateY(-10px);
  overflow: hidden;
}
