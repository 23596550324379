.buCJ-Ta6MIV5hiF5-gTt5 {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 20px auto;
  display: flex;
  max-width: 95%;
}
._3V8FRVnDBIPFy7yiSAHP0g {
  background: #fff;
  min-width: 800px;
  border-radius: 6px;
  box-shadow: 0 0 7.53px rgba(0,0,0,0.18);
}
._3V8FRVnDBIPFy7yiSAHP0g.vEzkQi7yQ0glwkXMObWX9 {
  max-height: calc(100vh - 180px);
}
div._2HeNSaZp9FHGeqr8ZiLe2s {
  background: transparent;
}
