.oCD0XCNr5oivdgIElKici {
  background: #fff;
  border: 1px solid #eceff1;
  width: 258px;
  min-height: 258px;
  border-radius: 3px;
  margin: 23px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1cQUVZKVgeUeBI7fGxL3e1 {
  min-width: 120px;
  max-width: 258px;
  max-height: 300px;
}
._27AZcw_Ok-Xx-6b7G34YXQ {
  text-align: center;
  padding: 74px 0;
  width: 100%;
  background: #eceff1;
}
._2BESNmBrei6dtwffai5Vbi {
  font-size: 80px;
  text-transform: uppercase;
  color: #b0bec5;
}
._1euEIy5WAUtqRDht0FIi2C {
  margin-top: 15px;
  font-size: 11px;
  text-transform: uppercase;
  color: #90a4ae;
}
._3L3iKC9J0roeNxro7VROnh {
  position: absolute;
  top: -2px;
  left: -2px;
  background: #fff;
  border: 1px solid #eceff1;
  width: 108px;
  min-height: 62px;
  overflow: hidden;
  border-radius: 3px 0 16px 0;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hT8AtlK6j7EisC3SNxzLc {
  min-width: 55px;
  max-width: 108px;
  max-height: 90px;
}
._1X6hyRmGaOBaR48Z3eV6pm {
  text-align: center;
  padding: 8px 0;
  width: 100%;
  background: #eceff1;
}
._228a5wGRYLRoC1nA_3KStP {
  font-size: 33px;
  text-transform: uppercase;
  color: #b0bec5;
}
._2OYisXOPvgl6VvP_tkqwSG {
  font-size: 11px;
  text-transform: uppercase;
  color: #90a4ae;
}
._1tqVeJMh169no3ItRIJlCg {
  position: absolute;
  top: -2px;
  left: -2px;
  height: 29px;
  line-height: 27px;
  border-radius: 3px 0;
}
._2hDAsb_pkJfUySLHsLuVXE {
  position: absolute;
  bottom: -1px;
  right: -1px;
  height: 29px;
  line-height: 27px;
  border-radius: 3px 0;
}
._3qH9wFXRuOGsF90IIP0XlX {
  font-size: 12px;
}
