.-DUhC4fY-XxY8GXNKKYiz {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 38px;
}
._8zN81-HYhoM4MIuUnTMrY {
  color: #546e7a;
  font-size: 18px;
  height: 18px;
  margin-left: 5px;
}
.ZvGhZU225MBEHplDM_r2D {
  transform: rotateX(180deg);
}
.Vlhq-bnRXV76xD6TNhtes {
}
