._1Hj5iz4o9bx4qRNsjWgoDE {
  display: flex;
  flex-direction: row;
}
.zpHYYEfk-J_9kHKrSYiZ5 {
  flex: 0 0 52px;
  height: 52px;
  margin: 20px;
}
._2R4Bd5pD2jzLUO-VVKjlR {
  flex: 1 1 100%;
  overflow: hidden;
  margin: 20px 0;
}
._3yH9hrMP8bKWv2T0shTh6h {
  flex: 0 0 36px;
  position: relative;
}
.ycfbvyaf3Z8rxzLfIhnGD {
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._1vfkJQfDW-PCVJGGmpEOZF {
  height: 22px;
  font-size: 14px;
  text-transform: uppercase;
  vertical-align: text-top;
}
._32pCTbAX76m-1fk06CqG6a {
  height: 30px;
  font-size: 21px;
  vertical-align: text-bottom;
}
