._12Xqc67bHUbmpiWcm_5_o0 {
  overflow: visible !important;
}
._2EdQBTl_KHxj6urSObmzeT {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  overflow: hidden;
}
._2EdQBTl_KHxj6urSObmzeT * {
  padding: 0 5px;
}
.Sxlu7_DaSiyJ_-00H4WrE {
  display: none;
  margin: 0 !important;
  max-width: 170px;
  overflow: visible !important;
}
.Sxlu7_DaSiyJ_-00H4WrE i {
  top: 5px;
}
._2EdQBTl_KHxj6urSObmzeT span {
  text-overflow: ellipsis;
  overflow: hidden;
}
._2tUIkF3OvsPMwIWYp2IPnJ {
  color: #f15a25;
}
