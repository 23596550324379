.j1Xo7hbYKvoYlkzU9JZSc {
  position: relative;
  font-size: 12px;
  margin: 0 !important;
}
.j1Xo7hbYKvoYlkzU9JZSc label {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 5px;
  padding: 0 5px;
  font-size: 13px;
  color: #90a3ad;
}
._1m5MHqUmu2UlECPCXoL0hh {
  width: 100%;
  border: 1px solid #91a4af;
  font-size: 13px;
  border-radius: 5px;
  margin: 10px 0 !important;
  display: block;
  padding: 15px 0;
  color: #546e7a;
  line-height: 1.7;
  box-sizing: border-box;
  text-align: justify;
  resize: none;
  min-height: 300px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
._1m5MHqUmu2UlECPCXoL0hh > * {
  padding: 0 15px;
}
._2ao7sg4iTV3pne0rbJMgPs {
/* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  background: rgba(0,0,0,0.04);
  margin: 5px 0;
}
._2ao7sg4iTV3pne0rbJMgPs::after {
/* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
/* This is how textarea text behaves */
  white-space: pre-wrap;
/* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
._2ao7sg4iTV3pne0rbJMgPs > textarea {
/* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
/* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
._2ao7sg4iTV3pne0rbJMgPs > textarea,
._2ao7sg4iTV3pne0rbJMgPs::after {
  font: inherit;
/* Place on top of each other */
  grid-area: 1/1/2/2;
  width: 100%;
  border: none;
  resize: none;
  color: inherit;
  font-family: inherit;
  min-height: 200px;
  background: transparent;
  padding: 5px 0;
}
