._2MrLd9hZNtuQgyxLIFcX9n {
  font-size: 12px;
  color: #78909c;
  white-space: nowrap;
}
._39hLI3bXhXqSh7UtJrZj5X {
  background: #f5f7f8;
}
._2R3tO-vUI2RKzdNK7ch8GS {
  background: #fff;
}
._5UIHYx00hJVDHqCN9NrO0 {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
._3eA7z6K3q4XEkKBfZvatN3 {
  height: 42px;
  line-height: 16px;
  padding: 10px 0;
  overflow: hidden;
}
._11qvJxRgmWlr8tVqrjhgtt {
  font-size: 16px;
  vertical-align: middle;
}
._3ls_T2fNAjkAcEE7_21QO8 {
  color: #00a99d;
}
._2-Lw3P1q4QGIzgBykX2VxJ {
  color: #546e7a;
}
.DjZSjvS4PYvLUtc3J5Pyv {
  font-size: 13px;
  line-height: 20px;
}
._1yKFwT2PDmpr4PIMArUyMu {
  color: #00a99d;
}
._3jfqqitGRiOWlsSt0jbPp6 {
  color: #90a4ae;
}
._1wv0FJjdyNPXcAQrgCl2Cj {
  font-size: 11px;
  color: #90a4ae;
}
._2MTgurRA9LcJkhtJXlhaUS {
  display: inline-block;
  padding: 1px 5px;
  background: #b0bec5;
  border-radius: 10px;
  color: #fff;
  line-height: 16px;
  height: 16px;
}
