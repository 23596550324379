._2Gb6haCFr0nLxm_5V1Gc3u {
  display: inline-block;
  height: 36px;
  width: 65px;
}
._1sjiOqkXUzV7p3LujnfZpd {
  display: none;
  font-size: 12px;
  color: #4f5c64;
}
