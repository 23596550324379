._2RMMnSwd8Huj4y1KDiroPm {
  width: 450px;
  overflow: visible;
}
._3wfRPC_9qJyHfFb4nrIwsB {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
.NiWomNlS4dZNHj6vTQ430 {
  display: flex;
  align-items: center;
}
.NiWomNlS4dZNHj6vTQ430 i {
  font-size: 45px;
  color: #00b4a6;
  margin-right: 10px;
}
.NiWomNlS4dZNHj6vTQ430 > .Oiy1VubI-6ga2klH3Y9FN {
  font-size: 15px;
}
.NiWomNlS4dZNHj6vTQ430 ._2j7HA1TD5HeWAApT8NLJbO {
  font-size: 17px;
  font-weight: normal;
}
