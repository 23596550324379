._2-vwuNG-7ZJkkI4yR9_u2C {
  min-width: 400px;
}
._TVXS3RG-zOp2MB4-WnRo {
  display: flex;
  justify-content: space-between;
}
._2Xpt7Av-4Og_49m5dwzP2q {
  width: 49%;
}
