._3O-xqhuKfhX3geta5fhygj {
  display: inline-block;
  white-space: nowrap;
}
._2P3LJO5_eZl-lUUkxYgtAy {
  display: inline-block;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  color: #78909c;
}
._1vCfZfPzzN1ANYODwPB0db ._350mrtyatzX8VcbuhGiDCg {
  border-radius: 20px !important;
  background: #00b8ab;
  color: #fff;
}
._350mrtyatzX8VcbuhGiDCg {
  height: 36px !important;
  width: 36px;
  line-height: 34px !important;
  border-radius: 0 !important;
  background: unset;
  border: unset;
}
._1vCfZfPzzN1ANYODwPB0db {
  margin-right: 5px;
}
