.rbv-progress-bar {
  margin: 10px 0;
  padding: 0;
  font-size: 14px;
  clear: both;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
}
.rbv-progress-bar .percent {
  background-color: #2eb8aa;
  height: 2px;
  width: 0;
}
