.rbInputRangeContainer {
  display: inline-block;
  box-sizing: border-box;
  min-width: 40px;
  padding: 8px 8px;
  cursor: pointer;
}
.rbInputRangeTrack {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  border: 1px solid #000;
  border-radius: 1px;
  background: #d3d3d3;
}
.rbInputRangeFill {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 0;
  background: #808080;
}
.rbInputRangeThumb {
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 0;
  left: 0;
  background: #808080;
  border: 1px solid #000;
  border-radius: 15px;
  margin-left: -7px;
  margin-top: -7px;
}
.rbInputRangeThumb:focus {
  border-color: #000;
  background: #fff;
}
