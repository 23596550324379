.questionnaireGroupHeader {
  background: url(images/questionnaire-group-heading.ac461c2.png) repeat-x;
  height: 15px;
  line-height: 15px;
  margin: 1px 0;
  padding: 4px 0;
}
.questionnaireGroupHeaderText {
  height: 15px;
  line-height: 15px;
  vertical-align: top;
  background-color: #b0bec5;
  border-radius: 0 2px 2px 0;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  padding: 0 15px 0 20px;
  text-transform: uppercase;
}
.questionnaireGroupCells {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px 15px;
}
.questionnaireGroupCellsLastItem {
  flex: 10000 0 1px;
}
