.rfp-manager-group-by .rfp-manager-select {
  background: #263238;
  border: 1px solid #16272b;
  padding: 0 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;
  border-radius: 2px;
}
.rfp-manager-group-by .rfp-manager-select span {
  line-height: 27px;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1;
}
.rfp-manager-group-by .rfp-manager-select span i.material-icons:first-child {
  margin: 0 5px;
  font-size: 18px;
}
.rfp-manager-group-by .toolbox-content.rbvDropDownContainer {
  overflow: visible !important;
}
.rfp-manager-group-by .rbvDropDown {
  min-width: 179px;
  width: max-content;
  color: #90a4ae;
  box-shadow: rgba(0,0,0,0.5) 0px 1px 5px 0px;
  background: #263238;
  border: 1px solid #16272b;
  margin-top: 5px;
  top: 100%;
  border-radius: 3px;
  font-size: 12px;
  max-height: 400px;
  overflow: auto;
}
.rfp-manager-group-by .rbvDropDown .dropdown-menu-item {
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  align-items: center;
}
.rfp-manager-group-by .rbvDropDown .dropdown-menu-item i.material-icons {
  margin-right: 10px;
  font-size: 20px;
}
.rfp-manager-group-by .rbvDropDown .dropdown-menu-item:hover,
.rfp-manager-group-by .rbvDropDown .dropdown-menu-item.selected {
  background: #36474f;
  color: #fff;
  border-bottom: 1px solid #16272b;
}
.rfp-manager-group-by .rbvDropDown .dropdown-menu-empty {
  padding: 5px 10px;
  color: #eceff1;
}
.rfp-manager-group-by .backdropStyle {
  background: transparent;
}
.rfp-manager-group-by.light .rfp-manager-select {
  background: #fff linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #e5e6e8;
}
.rfp-manager-group-by.light .rfp-manager-select span {
  color: #546e7a;
}
.rfp-manager-group-by.light .rbvDropDown {
  color: #546e7a;
  background: #fff;
  border: 1px solid #b0bec5;
}
.rfp-manager-group-by.light .rbvDropDown .dropdown-menu-item {
  border-bottom: 1px solid #e5e6e8;
}
.rfp-manager-group-by.light .rbvDropDown .dropdown-menu-item:hover,
.rfp-manager-group-by.light .rbvDropDown .dropdown-menu-item.selected {
  background: #eceff1;
  color: #546e7a;
}
