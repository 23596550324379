._2V4JpERmUK3y_Rw2YDnrMq {
  width: 500px;
  overflow: visible;
}
._3-_JLYEn64K--bEeABcDEn {
  color: #7f909c;
  border: 1px solid;
  max-height: 250px;
  overflow: auto;
}
.vModalDefaultBody {
  margin-bottom: 15px;
}
._2Gc_u23W9xTdNj1HtXidCP {
  float: left;
}
