._3oN4qf7rfW8D3KDafiGwlc {
  width: 500px;
  overflow: visible;
}
._6Pu4-G819bO_ylo-7i33T {
  color: #7f909c;
  border: 1px solid;
  max-height: 270px;
  overflow: auto;
}
.vModalDefaultBody {
  margin-bottom: 15px;
}
._033DWZDZaAgKwRf8nhEE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  height: 48px;
  box-sizing: border-box;
}
._033DWZDZaAgKwRf8nhEE > div {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  line-height: 1;
}
._033DWZDZaAgKwRf8nhEE > div > * {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #546e7a;
}
._033DWZDZaAgKwRf8nhEE > div > div {
  font-size: 14px;
  color: #546e7a;
}
._033DWZDZaAgKwRf8nhEE:hover,
._033DWZDZaAgKwRf8nhEE._2gHUVrxclTflM-tB8KCPm7 {
  background: #fffbdf;
}
._033DWZDZaAgKwRf8nhEE i {
  margin-right: 10px;
}
._033DWZDZaAgKwRf8nhEE._2gHUVrxclTflM-tB8KCPm7 i {
  font-weight: bold;
}
._3rL_GuCHrulLL2cYqSsKb2 {
  text-align: center;
  margin: 10px 0;
  color: #3d4d55;
}
._3rL_GuCHrulLL2cYqSsKb2 ._38CNuSmbb7Sgv_pghX17Hp {
  font-size: 14px;
}
._3rL_GuCHrulLL2cYqSsKb2 span {
  display: block;
  font-size: 12px;
}
._14u4tMdK39rZpV8NRMNoDh {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}
._1MA_SsIArie1QyOp85F-qo {
  padding: 5px;
  text-align: center;
}
._1-kmuz9yqFdZfjnz8WLtTS {
  position: relative;
}
._3RumV3_KTUuHvYf8R1icTJ {
  position: absolute;
  top: 25px;
  right: 0;
  color: #3d4d55;
}
._2tIhvWmZ3Tz_eoD4X7VjJv {
  padding-right: 25px;
}
._2tIhvWmZ3Tz_eoD4X7VjJv:focus {
  border-color: #09c1b2;
}
._2tIhvWmZ3Tz_eoD4X7VjJv:focus + ._3RumV3_KTUuHvYf8R1icTJ {
  color: #09c1b2;
}
._3TK3k8nGVLx5GJ083UqoAG {
  float: left;
  margin-left: 0 !important;
}
