._2YDxoHaSMOJwd-DYLMQPZD {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}
._1zu_oREuVfhPomJha4Ca2T {
  flex: 0 0 48px;
  height: 48px;
  margin-right: 10px;
}
._2UhH06wMaGRwQt9eZMoKXW {
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
}
._2ScZoHtWPi5pVSVKeWr3Du {
  height: 30px;
  font-size: 28px;
}
.MJ2Ag5HSgexy_4WNOKlWc {
  vertical-align: text-bottom;
}
._1yxQJ69KTLicyjDSQqM_JX {
  flex: 0 1 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
}
._3ITWlCk7wk_fVPmPIK0L55 {
  font-size: 12px;
  line-height: 28px;
  color: #546e7a;
  flex: 0 0 auto;
  font-weight: normal;
}
