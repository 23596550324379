.destinationsManagerMapDistanceFilterRangeInput {
  width: calc(100% - 80px);
  vertical-align: middle;
  margin-right: 5px;
  padding: 11px 11px;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeTrack {
  height: 2px;
  border-width: 0;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeFill {
  height: 2px;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeThumb {
  height: 18px;
  width: 18px;
  border-radius: 18px;
  margin-left: -9px;
  margin-top: -8.5px;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeTrack {
  border-color: #cfd8dc;
  background: #cfd8dc;
  box-shadow: none;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeFill {
  background: #00b8ab;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeThumb {
  border-color: #00a99d;
  background: #00a99d;
  box-shadow: none;
}
.destinationsManagerMapDistanceFilterRangeInput .rbInputRangeThumb:focus {
  border-color: #00b8ab;
  background: #00a99d;
  box-shadow: 0 0 2px 5px rgba(0,169,157,0.2);
}
