.hotel-type {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  width: auto;
}
.hotel-type .icons {
  text-overflow: unset;
  height: 15px;
  display: flex;
}
.hotel-type .point {
  width: 5px;
  height: 15px;
  margin: 0px 1px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 2px;
  background: #b1bec6;
}
.hotel-type .point:nth-child(6) {
  margin-right: 5px;
}
.hotel-type .point.active {
  background: #40b8a9;
}
.hotel-type.dark .point.active {
  background: #77909d;
}
.hotel-type .name {
  font-weight: lighter;
  padding: 0 0px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
