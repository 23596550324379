.vDialogWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.vDialogWrapper + .vDialogWrapper {
  background: rgba(0,0,0,0.05);
}
.vModal {
  transition: all 0.2s ease-in-out;
}
.vDialog-enter,
.vDialog-leave-to {
  opacity: 0;
}
.vDialog-enter-to,
.vDialog-leave {
  opacity: 1;
}
.vDialog-enter .vModal,
.vDialog-leave-to .vModal {
  transform: scale(1.1);
}
