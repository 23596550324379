._1KpL_bP3ZaDKXv0A8qlOer {
  width: 52px;
  height: 52px;
}
._2Y2i8yHKZ85vbif7aigpfB {
  fill: #00a99d;
}
._1LULVqzy1uOSJ7kftUBBun {
  fill: #f15a24;
}
._65CPVSqnnmbskyetTy4I4 {
  padding: 0 20px 25px;
  flex: 0 0 auto;
  overflow: auto;
  max-height: 300px;
  box-sizing: border-box;
}
