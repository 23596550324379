.XIb106ksPcdKHWdxy2hm8 {
  min-width: 500px;
  overflow: visible;
}
._35bhY9oiszo5Y2KkwOKIdI {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3ZYP90EeY2OHBtHqpTGyNY {
  display: flex;
  justify-content: space-between;
}
._9vcERs-ZYqzSoYirHPRcA {
  flex: 1;
}
._9vcERs-ZYqzSoYirHPRcA+._9vcERs-ZYqzSoYirHPRcA {
  margin-left: 5px;
}
._3qa2mszxfn0bXOQ0nEq9o9 {
  float: left;
}
