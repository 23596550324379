.questionnaireModuleHeader {
  height: 27px;
  border: 0;
  background: #d9e0e3;
  margin: 1px 0;
}
.questionnaireModuleHeaderText {
  display: inline-block;
  height: 21px;
  margin: 3px 0;
  padding: 0 15px 0 20px;
  background-color: #78909c;
  border-radius: 0 3px 3px 0;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 21px;
}
