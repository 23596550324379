._2UTyvzofMFsncolaGj-H8H {
  color: #fff;
  display: flex;
}
._1HkcGDGTQ3smwAU_6qkZca {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
._1btyDS-eInX3XLT8D0XVQX {
  padding: 0 10px;
}
._31syaNaDUSkNmm_fsPko5s {
  padding: 0 10px;
  font-size: 12px;
  color: #acbec5;
}
._1wu6vu9-kAtV5II8uTLelD {
  color: #2db8aa;
  background: #263238;
  padding: 14px;
/* vertical-align: middle; */
  border-radius: 100%;
  height: 48px;
  width: 48px;
  box-sizing: border-box;
}
._1rxyRKINHwD2C4m3jlJyaH {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 5px;
}
._3zIbIla9JVwIwE9rSCcMFY {
  justify-content: flex-start;
  margin-right: 5px;
}
._1Emna_-8di6YAPfdlFp_8E {
  height: 36px;
  border: 1px solid #263238;
  display: inline;
  vertical-align: middle;
  margin: 0 10px 0 5px;
}
._30nEL0znO_vVj0oYhgMr7d {
  width: 100%;
  height: calc(100vh - 198px);
  background-color: #eceff1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #263238;
}
._30nEL0znO_vVj0oYhgMr7d > * {
  margin: 5px 0;
}
.HML0EIdNxgO_b3qYW2h8e {
  color: #263238;
  font-size: 20px;
}
.E5hS4nbuahhPGiZv98EQ8 {
  color: #546e7a;
  font-size: 13px;
  max-width: 400px;
  margin: 0 auto;
}
.bZAARMlALbO0vh9H9bTg- {
  height: 30px;
  display: table-row;
}
._3fj8sBmgyc5Iwlmu5Ug8g5 {
  padding: 5px 15px;
  color: #78909c;
  position: absolute;
}
._3VibGAwRJF2emnHKg7WpCq {
  padding: 100px;
}
._1iBhEtn0pcBHnvN9l1mqlT {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 300px;
  height: 180px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px 1px #78909c;
  border-radius: 3px;
  z-index: 9;
}
._2oJ0GzkO6LzEX7QaeXe7l8 {
  display: flex;
  align-items: center;
  color: #546e7a;
  font-size: 17px;
  font-weight: lighter;
}
._2oJ0GzkO6LzEX7QaeXe7l8 ._1TRODE5kejXZctUct1EyXy {
  color: #ec5c0b;
  font-size: 50px !important;
}
._3p6iZcCJb9o4tt4V3LXd5B {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: unset !important;
  background-color: #f15a24;
  color: #fff !important;
  border: 0px !important;
}
._2qk0A_1AiGYqo-8gvglTLt {
  color: #82909d;
}
._3YA5e5FX5JDcjDoZvStubl {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #82909d;
  cursor: pointer;
}
._2NcKJd_5ORPRAC2_zOi36r {
  overflow: auto;
}
._2FKt-F-FBFGRk3kfQn0gyf {
  height: calc(100vh - 149px);
  overflow: auto;
  box-sizing: border-box;
}
