.rbv-loading {
  text-align: center;
}
.rbv-loading .animation {
  display: inline-block;
  animation: infinite-spin 1s infinite;
  animation-timing-function: linear;
  height: 50px;
  width: 50px;
  stroke: #00a99d;
  stroke-width: 5px;
}
.rbv-loading .label {
  color: #78909c;
  font-weight: 100;
  margin: 15px auto 0;
  font-size: 11pt;
  padding-left: 11pt;
}
.rbv-loading.medium .animation {
  height: 30px;
  width: 30px;
  stroke-width: 8px;
}
.rbv-loading.medium .label {
  display: inline-block;
  margin: 0;
  font-size: 11px;
  padding-left: 3px;
}
.rbv-loading.small .animation {
  height: 10px;
  width: 10px;
  stroke-width: 13px;
}
.rbv-loading.small .label {
  display: inline-block;
  margin: 0;
  font-size: 12px;
  padding-left: 3px;
}
@-moz-keyframes infinite-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
@-webkit-keyframes infinite-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
@-o-keyframes infinite-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
@keyframes infinite-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
