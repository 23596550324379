._27-oN-Wq3GpKLCVeCBb7W {
  min-width: 950px;
  overflow: visible;
}
.PH4BfA4NARaLfQUgMhEzc {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._3-8mz_ZmVcMSt7qhtFqK4w {
  display: flex;
  align-items: center;
}
._3gTaFbEArtC4yIgI-3mNER {
  flex: 1;
  padding: 0 10px;
  color: #78909c;
}
._2A-TE3-O5fK5Z2BogHjQVI {
  height: 48px !important;
  width: 48px !important;
  background: #eceff1;
  color: #00a99d;
  text-align: center;
  padding: 12px 0;
  box-sizing: border-box;
  border-radius: 100%;
}
._3A3k7afS5leFR4sER2ak-H {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eceff1;
}
._3A3k7afS5leFR4sER2ak-H:hover {
  background: #eceff1;
}
._31U4ApVvev_XilnDljeW7C {
  cursor: pointer;
  border-radius: 100%;
  padding: 3px;
  font-size: 20px;
}
._3A3k7afS5leFR4sER2ak-H:hover ._31U4ApVvev_XilnDljeW7C {
  background: #fff;
}
._2GVJ1k83p8hRt0EFuURQ3k {
  padding: 5px 0;
}
._2Yn6JYofogOFxJm-960jfv {
  flex-basis: 140px;
  font-size: 12px;
  padding: 3px;
  height: 30px;
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #546e7a;
  box-sizing: border-box;
  flex: 1;
}
._3i-atSbajLSa2_GZJgTlWB {
  flex-basis: 30px;
  width: 30px;
  flex: unset;
}
.iyZ8vOosQ2RrhMyWwissV {
  flex-basis: 70px;
  width: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: unset;
}
._1TNWZH2maQ38J2My57UuBF {
  border: 2px solid #b0bec5;
  max-height: 210px;
  overflow-y: auto;
}
.XetFPlpOf2DifXPrjk-D7,
._3RuYnq58bJpE6Q56okeGei {
  color: #f15a25;
}
