._121tr4yKQli1qd3KCwkYjk {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
._3DqwC3dJIcHKJWz31YCj1F {
  background-size: cover;
  min-height: calc(100vh - 149px);
  box-sizing: border-box;
}
._1pZQ9BncNnvdHKjl-LdF3a {
  margin-top: 70px;
}
._3uhTAX-3u49Fozrc5HyIW0 {
  min-width: calc(65vw - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._2vIv69C62ranPTobi9IMHb {
  display: flex;
  justify-content: flex-end;
}
._3jg5kOkil3NmYb0O-oYHXb {
  display: flex;
  justify-content: flex-start;
}
._2bbUGKLrI11C8xI_Rnea1t {
  margin: 0 5px;
}
.f3dOzBd5dXxCjhDKBu7eD {
  background-color: transparent !important;
}
._1nD0qKXh_Z1nUJJ1lkAmmd {
  background-color: #263238;
  padding: 5px 0;
  margin: 5px 0;
  border-top: 2px solid #16272b;
  color: #fff;
  list-style-type: none;
  max-height: 150px;
  overflow: auto;
}
._2tkihn0ORLzcb9X3o9PkgV {
  color: #b1bec6;
}
.Yhjj-6q65l30MJoz4bQF6 {
  display: flex;
  justify-content: flex-start;
  overflow: visible;
  padding: 5px 15px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
}
.Yhjj-6q65l30MJoz4bQF6:hover {
  background: #313c41;
}
.Yhjj-6q65l30MJoz4bQF6 i {
  margin-right: 5px;
}
._1CmxJuxQPCzBZD39iW6BSl {
  position: fixed;
  right: 50px;
  top: 160px;
  z-index: 99;
}
