._26kRb3VK-QU_tvjEwhNMwH {
  background: #fff;
  border: 1px solid #eceff1;
  width: 258px;
  min-height: 148px;
  border-radius: 3px;
  margin: 23px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
._3-Vuxev4DLDjz1tw7IEA65 {
  min-width: 120px;
  max-width: 258px;
  max-height: 200px;
}
.GpfiGFXOTSJDRl5PaHvHU {
  text-align: center;
  padding: 27px 0;
  width: 100%;
  background: #eceff1;
}
._1wvTaljFEJhG9qctL8Iud3 {
  font-size: 80px;
  text-transform: uppercase;
  color: #b0bec5;
}
.ZJSGC1n6_eqHRYZHf_2Vq {
  font-size: 11px;
  text-transform: uppercase;
  color: #90a4ae;
}
._2wX9Z8KRxABY8uu2l8wA7s {
  position: absolute;
  right: -1px;
  bottom: -1px;
  height: 29px;
  line-height: 27px;
  border-radius: 3px 0;
}
._1YhSX3IDNbqMp-69SuOuEl {
  font-size: 12px;
}
