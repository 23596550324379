.D9xPMeaNTASnRMbN6g1rg {
  color: #fff;
  display: flex;
}
._3SFLhTffuVYaN5fhHBf-Dj {
  padding: 0 10px;
}
._1dXjy_9sY1pq5oHBIeV3W5 {
  padding: 0 10px;
  font-size: 12px;
  color: #acbec5;
  padding: 0 10px;
}
._1KhOuw_9k0czQpmtATkNw0 {
  color: #2db8aa;
  background: #263238;
  padding: 12px;
/* vertical-align: middle; */
  border-radius: 100%;
  height: 48px;
  width: 48px;
  box-sizing: border-box;
}
._27WYCn3V-sHN6PogwI9mvx {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
._27-Ny1Q9pGVBy9WWYgxDrM {
  height: calc(100vh - 158px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._27-Ny1Q9pGVBy9WWYgxDrM > * {
  margin: 10px 0;
}
._3Izi9lC0Y046O7Ted8NcJi {
  font-weight: bold;
  color: #66767f;
}
._1wi0StTTeAdlUIruFloFse {
  color: #cfd8dc;
  font-size: 120px;
}
._2ssmIv46Xm2Y12n1uS6Ve6 {
  color: #f5f6f7;
  background-color: #525f67;
  font-size: 120px;
  border-radius: 35px;
  border-top-right-radius: 55px;
}
._1qnnAVPSXw2BUOaWlLgIgr {
  color: #91a4af;
  width: 450px;
  font-size: 12px;
  text-align: center;
}
._3Y3Ke2RTuQNHjDzSNz0AiJ {
  min-width: 435px;
  display: flex;
  justify-content: flex-end;
}
._3Y3Ke2RTuQNHjDzSNz0AiJ > div {
  flex: 1;
}
._1Om27jG0-FHwrgw_R5t8ex {
  display: none;
}
