.rbInputFileContainer.box {
  text-align: center;
}
.rbInputFileContainer.box input[type=file] {
  display: none;
}
.rbInputFileContainer.box .box__dragndrop {
  display: none;
}
.rbInputFileContainer.box .box__error {
  font-size: 12px;
  color: #f00;
}
.rbInputFileContainer.box .box__input {
  padding: 65px 0 65px 0;
  color: #546e7a;
  font-size: 12px;
}
.rbInputFileContainer.box .box__input .manual-upload {
  height: 27px;
  font-size: 12px;
  line-height: 0;
}
.rbInputFileContainer.box.has-advanced-upload {
  background-color: #fff;
}
.rbInputFileContainer.box.has-advanced-upload.is-dragging {
  outline: 2px dashed #808080;
  outline-offset: 0px;
}
.rbInputFileContainer.box.has-advanced-upload .box__dragndrop {
  display: block;
  padding-bottom: 15px;
}
.rbInputFileContainer.box.has-advanced-upload .box__dragndrop i.material-icons {
  color: #b0bec5;
  font-size: 100pt;
}
.rbInputFileContainer.box.has-advanced-upload .box__dragging {
  display: block;
  padding-bottom: 15px;
}
.rbInputFileContainer.box.has-advanced-upload .box__dragging i.material-icons {
  color: #808080;
  font-size: 80pt;
}
.rbInputFileContainer.box.has-advanced-upload .box__dragging.dragging {
  padding: 40px 0 40px 0;
}
