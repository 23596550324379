.rbTitle {
  line-height: 1.2;
  font-size: 30px;
  color: #37474f;
}
.rbSubtitle {
  line-height: 1.2;
  font-size: 18px;
  color: #78909c;
}
.rbTextLarge {
  line-height: 1.2;
  font-size: 15px;
  color: #90a4ae;
}
.rbText {
  line-height: 1.2;
  font-size: 14px;
}
.rbTextSmall {
  line-height: 1.2;
  font-size: 12px;
  color: #cfd8dc;
}
.rbError {
  font-size: 12px;
  line-height: 1.2;
  color: #f15a24;
}
body {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
}
.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
}
.FillAndCenter {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CenterContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.DocumentView {
  display: flex;
  height: calc(100vh - 60px);
  width: 100%;
  background: #eceff1;
}
.DocumentView.Vertical {
  flex-direction: column;
}
.DocumentViewNavigation {
  flex: 0 0 auto;
}
.DocumentViewContainer {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.DocumentViewHeading {
  background: #313c42;
  flex: 0 0 88px;
  height: 88px;
  display: flex;
  justify-content: space-between;
}
.DocumentViewHeadingActions {
  flex: 0 0 auto;
  padding: 26px 15px;
}
.DocumentViewHeadingActionsInline {
  flex: 0 0 auto;
  padding: 26px 15px;
  display: flex;
  align-items: center;
}
.DocumentViewErrorContainer {
  max-height: 50px;
  transition: all 0.2s linear;
  overflow: hidden;
}
.DocumentViewErrorContainer.Hidden {
  max-height: 0;
}
.DocumentViewErrors {
  background: #313c42;
  padding: 0 15px 5px;
  border-bottom: 5px solid #f15a24;
}
.DocumentViewContentContainer {
  flex: 1 1 100%;
  overflow: auto;
}
.DocumentViewContent {
  margin: 0 auto;
  width: 960px;
  min-height: 100%;
  background: #fff;
  overflow: auto;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
}
.DocumentViewContentLeft {
  max-width: 1200px;
  min-height: 100%;
  background: #fff;
  overflow: auto;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
}
.Ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
[data-icon-after] {
  display: inline-flex;
  align-items: center;
  padding-right: 0;
}
[data-icon-after]:after {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon-after);
  text-align: center;
}
[data-icon-before] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
[data-icon-before]:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon-before);
  text-align: center;
}
.aBtn+aBtn {
  margin-left: 5px;
}
.aBtn.asLarge {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  outline: 0;
  cursor: pointer;
  transition: all 0.1s linear;
  height: 36px;
  border-radius: 18px;
  padding: 0 15px;
}
.aBtn.asLarge:disabled,
.aBtn.asLarge:disabled:hover {
  cursor: not-allowed;
}
.aBtn.asSmall {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  outline: 0;
  cursor: pointer;
  transition: all 0.1s linear;
  height: 27px;
  border-radius: 14px;
  padding: 0 10px;
}
.aBtn.asSmall:disabled,
.aBtn.asSmall:disabled:hover {
  cursor: not-allowed;
}
.aBtn.atDark {
  border: 1px solid #263238;
  background: #263238;
  color: #b0bec5;
}
.aBtn.atDark:disabled:not(.ForceNormal),
.aBtn.atDark:disabled:hover:not(.ForceNormal) {
  background: #3c464c !important;
  border-color: #3c464c !important;
  color: #637078 !important;
}
.aBtn.atDark:hover {
  color: #fff;
}
.aBtn.atDark:focus,
.aBtn.atDark.Focus {
  border-color: #00b8ab;
}
.aBtn.atDark:focus:before,
.aBtn.atDark.Focus:before {
  color: #00b8ab;
}
.aBtn.atDark.asMain {
  background: #00a99d;
  color: #fff;
}
.aBtn.atDark.asMain:hover {
  background: #00b8ab;
  border-color: #00b8ab;
  color: #fff;
}
.aBtn.atDark.asMain:focus,
.aBtn.atDark.asMain.Focus {
  border-color: #00b8ab;
}
.aBtn.atDark.asMain:focus:before,
.aBtn.atDark.asMain.Focus:before {
  color: #fff;
}
.aBtn.atDark.asMainBlue {
  background: #00a99d;
  color: #fff;
  background: #29abe2;
}
.aBtn.atDark.asMainBlue:hover {
  background: #00b8ab;
  border-color: #00b8ab;
  color: #fff;
}
.aBtn.atDark.asMainBlue:focus,
.aBtn.atDark.asMainBlue.Focus {
  border-color: #00b8ab;
}
.aBtn.atDark.asMainBlue:focus:before,
.aBtn.atDark.asMainBlue.Focus:before {
  color: #fff;
}
.aBtn.atDark.asMainBlue:hover {
  background: #29abe2;
  border-color: #29abe2;
}
.aBtn.atLight {
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #546e7a;
}
.aBtn.atLight:disabled:not(.ForceNormal),
.aBtn.atLight:disabled:hover:not(.ForceNormal) {
  background: #eceff1 !important;
  border-color: #cfd8dc !important;
  color: #b0bec5 !important;
}
.aBtn.atLight:hover {
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: #455a64;
}
.aBtn.atLight:focus,
.aBtn.atLight.Focus {
  border-color: #00b8ab;
}
.aBtn.atLight:focus:before,
.aBtn.atLight.Focus:before {
  color: #00b8ab;
}
.aBtn.atLight.asMain {
  background: #00b8ab;
  border: 1px solid #00a99d;
  color: #fff;
}
.aBtn.atLight.asMain:hover {
  background: #00a99d;
  border-color: #00a99d;
  color: #fff;
}
.aBtn.atLight.asMain:focus,
.aBtn.atLight.asMain.Focus {
  border-color: #00b8ab;
}
.aBtn.atLight.asMain:focus:before,
.aBtn.atLight.asMain.Focus:before {
  color: #fff;
}
.aBtn.asLarge[data-icon] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
.aBtn.asLarge[data-icon]:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon);
  text-align: center;
}
.aBtn.asLarge[data-icon]:before {
  width: 40px;
}
.aBtn.asSmall[data-icon] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
.aBtn.asSmall[data-icon]:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon);
  text-align: center;
}
.aBtn.asSmall[data-icon]:before {
  font-size: 18px;
  width: 30px;
}
.aBtn.errorIcon[data-icon]:before {
  color: #e65622;
}
.aBtn.asLarge.asIcon {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}
.aBtn.asSmall.asIcon {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}
.aBtn.asIcon[data-icon-rotate="90"] {
  transform: rotate(90deg);
}
.aBtn.asIcon[data-icon-rotate="180"] {
  transform: rotate(180deg);
}
.aBtn.asIcon[data-icon-rotate="270"] {
  transform: rotate(270deg);
}
.aBtn.asLink {
  border: 0;
  background: transparent !important;
  text-decoration: underline;
  padding: 0 5px;
}
.aBtn.asLink:hover {
  text-decoration: none;
}
.aBtn[data-icon].asLink {
  border: 0;
  background: transparent !important;
  padding: 0 5px;
  text-decoration: none;
}
.aBtn[data-icon].asLink>span {
  text-decoration: underline;
}
.aBtn[data-icon].asLink>span:hover {
  text-decoration: none;
}
.aBtn.inModal {
  padding: 0 30px;
}
.aBtn.asError {
  background: #f15a24;
  border: 1px solid #e65622;
  color: #fff;
}
.aBtn.asError:hover {
  color: #fff;
  background: #e65622;
}
.aBtn.asError:focus,
.aBtn.asError.Focus {
  color: #fff;
  background: #e65622;
  border: 1px solid #fff;
}
.aBtn.asError:focus:before,
.aBtn.asError.Focus:before {
  color: #fff;
}
.anInput+anInput {
  margin-left: 5px;
}
.anInput.asLarge {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  outline: 0;
  transition: all 0.1s linear;
  height: 34px;
  border-radius: 18px;
  padding: 0 15px;
}
.anInput.asLarge:disabled,
.anInput.asLarge:disabled:hover {
  cursor: not-allowed;
}
.anInput.asSmall {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  outline: 0;
  transition: all 0.1s linear;
  height: 27px;
  border-radius: 14px;
  padding: 0 10px;
}
.anInput.asSmall:disabled,
.anInput.asSmall:disabled:hover {
  cursor: not-allowed;
}
.anInput.atDark {
  border: 1px solid #263238;
  background: #263238;
  color: #b0bec5;
}
.anInput.atDark:disabled:not(.ForceNormal),
.anInput.atDark:disabled:hover:not(.ForceNormal) {
  background: #3c464c !important;
  border-color: #3c464c !important;
  color: #637078 !important;
}
.anInput.atDark::placeholder {
  color: #b0bbc5;
}
.anInput.atDark:hover {
  color: #fff;
}
.anInput.atDark:focus {
  border-color: #00b8ab;
}
.anInput.atDark:focus:before {
  color: #00b8ab;
}
.anInput.atDark.asMain {
  background: #00a99d;
  color: #fff;
}
.anInput.atDark.asMain:hover {
  background: #00b8ab;
  border-color: #00b8ab;
  color: #fff;
}
.anInput.atDark.asMain:focus {
  border-color: #00b8ab;
}
.anInput.atDark.asMain:focus:before {
  color: #fff;
}
.anInput.atLight {
  background: linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #546e7a;
}
.anInput.atLight:disabled,
.anInput.atLight:disabled:hover {
  background: #eceff1 !important;
  border-color: #cfd8dc !important;
  color: #b0bec5 !important;
}
.anInput.atLight::placeholder {
  color: #b0bbc5;
}
.anInput.atLight:hover {
  background: #fff;
  color: #455a64;
}
.anInput.atLight:focus {
  border-color: #00b8ab;
}
.anInput.atLight:focus:before {
  color: #00b8ab;
}
.anInput.atLight.asMain {
  background: #00b8ab;
  border: 1px solid #00a99d;
  color: #fff;
}
.anInput.atLight.asMain:hover {
  background: #00a99d;
  border-color: #00a99d;
  color: #fff;
}
.anInput.atLight.asMain:focus {
  border-color: #00b8ab;
}
.anInput.atLight.asMain:focus:before {
  color: #fff;
}
.anInput.asLarge[data-icon] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
.anInput.asLarge[data-icon]:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon);
  text-align: center;
}
.anInput.asLarge[data-icon]:before {
  width: 40px;
}
.anInput.asSmall[data-icon] {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}
.anInput.asSmall[data-icon]:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: attr(data-icon);
  text-align: center;
}
.anInput.asSmall[data-icon]:before {
  font-size: 18px;
  width: 30px;
}
.anInput.asError {
  background: #f15a24;
  border: 1px solid #e65622;
  color: #fff;
}
.anInput.asError:hover {
  color: #fff;
  background: #e65622;
}
.anInput.asError:focus {
  color: #fff;
  background: #e65622;
  border: 1px solid #fff;
}
.anInput.asError:focus:before {
  color: #fff;
}
