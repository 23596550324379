._2k9Nr7IaxqE3ZfQoxDtlma {
  width: 450px;
  overflow: visible;
}
._1bBSX0J_Qi2hmz9i-DbbFS {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
._3KqdBsZE7tJWWKWQOIOxV {
  display: flex;
  align-items: center;
}
._3KqdBsZE7tJWWKWQOIOxV i {
  font-size: 45px;
  color: #00b4a6;
  margin-right: 10px;
}
._3KqdBsZE7tJWWKWQOIOxV > ._2WJaxdXLjCW7610KIAxYnl {
  font-size: 15px;
}
._3KqdBsZE7tJWWKWQOIOxV ._1_GY_6V2na3wRHQe-C9P9F {
  font-size: 17px;
  font-weight: normal;
}
