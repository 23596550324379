._5O4bXA0vEQVsoqRg2UsFC {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}
.t1pcV76bTQAwkrg2Hr6g6 {
  display: flex;
  flex-direction: row;
}
.t1pcV76bTQAwkrg2Hr6g6 > *:not(:first-child) {
  margin-left: 10px;
}
._1tTFCOm8D_oBXX8ohPBfVE {
  flex: 1 1 100%;
}
._20zePNubPU3ID3UHNxDEoT {
  flex: 0 0 auto;
}
