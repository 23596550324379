.v7HnOwhPOJ2F37h4DxXxm {
  box-sizing: border-box;
  height: 62px;
  background: #fff;
  border-top: solid 1px #eceff1;
  cursor: pointer;
  display: flex;
}
.Lskysc_lqGbuQrAynAS2k {
  background: #f4f5f7;
}
.GFX26DMXKfDGpdVXO6Dxj {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
._1-OZgXN0esdm8xjVTKB2VC {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  color: #90a4ae;
}
.s_Lj2dVojKBbsms95mPDA {
  font-size: 13px;
  line-height: 22px;
}
._3BbgV_4RB7WyrM9qBn39ny {
  font-size: 11px;
  line-height: 16px;
}
._2hMGoJH-oVYzQ6r0FjJYuk {
  display: inline-block;
}
._3UQy39XW3sAoS5yNOWOAiD {
  color: #00a99d;
}
._2S9ZrUrDaG9Da7Wtfu37bB {
  color: #546e7a;
}
.ST--Ee_c6ZDkgKdJiZ_Zt {
  font-size: 18px;
  vertical-align: middle;
}
.cd-FnDzXTvlZZpTHfE1wg {
  display: inline-block;
  background: #b0bec5;
  color: #fff;
  border-radius: 10px;
  padding: 1px 7px;
  margin-right: 5px;
}
