.UMfN1izBwhfXEWtRyWRKC {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 20px auto;
  display: flex;
  max-width: 95%;
}
._1Y6HpPeOWZaXtWBwRVSpEv {
  width: 304px;
  background: #fff;
  margin-right: 5px;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 0 3.14px rgba(0,0,0,0.18);
}
._2XoA90w76wOPuBFHwzy5sK {
  background: #fff;
  min-width: 624px;
  border-radius: 0 6px 6px 0;
  box-shadow: 0 0 7.53px rgba(0,0,0,0.18);
}
._3-EWp9JK6wo9C8AlP5ozLT {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #37474f;
  padding: 5px 23px 15px;
}
.LofsNM5rATOyDgLw9oQxH {
  display: block;
  height: 40px;
  line-height: 40px;
  background: #fbfbfc;
  border: 1px solid #eceff1;
  border-left: 0;
  border-right: 0;
  text-decoration: none !important;
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #546e7a !important;
}
._2myD-4Uvx_I0eTp_QZEM51 {
  background: #00b8ab;
  border: 1px solid #00b8ab;
  color: #fff !important;
}
.JDpC_nqOzS5fPwFMYTBgi {
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px;
  color: #546e7a;
}
._23W-B6k8xlf78O1xvMe-9O {
  color: #fff;
}
._1iDwFXFBFKJMJO1CPjQoGZ {
  vertical-align: middle;
  color: #546e7a;
}
._3YHBQc9nZpPifwnSOSu2vn {
  color: #fff;
}
