.RbForm .Input {
  position: relative;
  margin-top: 10px;
}
.RbForm .Input input {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 6px 6px 6px 6px;
  font-family: "Noto Sans", Sans-serif;
  font-weight: 500;
  color: #37474f;
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
.RbForm .Input input ~ label,
.RbForm .Input input ~ .Icon:after {
  color: #78909c;
}
.RbForm .Input input:focus {
  border-color: #00a99d;
}
.RbForm .Input input:focus ~ label,
.RbForm .Input input:focus ~ .Icon:after {
  color: #00a99d;
}
.RbForm .Input input:invalid.touched {
  border-color: #f15a24 !important;
}
.RbForm .Input input:invalid.touched ~ .Error {
  opacity: 1;
  overflow: visible;
  max-height: 50px;
}
.RbForm .Input input::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}
.RbForm .Input input:placeholder-shown ~ label {
  opacity: 0;
  top: 0;
}
.RbForm .Input input.WithIcon {
  padding-right: 50px;
}
.RbForm .Input input.AsyncValidation ~ .Icon:after {
  color: #00a99d;
  content: 'loop';
  animation: rotation 2s infinite linear;
}
.RbForm .Input input.AsyncValid ~ .Icon:after {
  color: #00a99d;
  content: 'done';
}
.RbForm .Input input:invalid:not(.AsyncValidation).touched {
  border-color: #f15a24 !important;
}
.RbForm .Input input:invalid:not(.AsyncValidation).touched::placeholder,
.RbForm .Input input:invalid:not(.AsyncValidation).touched ~ label,
.RbForm .Input input:invalid:not(.AsyncValidation).touched ~ .Icon:after {
  color: #f15a24;
}
.RbForm .Input input:invalid:not(.AsyncValidation).touched ~ .Error {
  opacity: 1;
  overflow: visible;
  max-height: 50px;
}
.RbForm .Input label {
  position: absolute;
  box-sizing: border-box;
  background: rgba(255,255,255,0.8);
  transition: all 0.2s ease;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  padding-bottom: 4px;
}
.RbForm .Input .Icon {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 47px;
  width: 40px;
}
.RbForm .Input .Icon:after {
  line-height: inherit;
}
.RbForm .Input .Error {
  font-size: 12px;
  padding-left: 15px;
  opacity: 0;
  transition-property: opacity, max-height;
  transition-duration: 0.2s;
  color: #f15a24;
  min-height: 20px;
  max-height: 20px;
  overflow: hidden;
}
.RbForm .Input.Select input {
  cursor: pointer;
}
.RbForm .Input.Select input:focus ~ .Options {
  display: block;
}
.RbForm .Input.Select .Options {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: 220px;
  background-color: #fff;
  background-image: linear-gradient(180deg, #fff 0%, rgba(176,190,197,0.04) 100%);
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  color: #90a4ae;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  font-size: 14px;
  line-height: 36px;
  padding: 3px 0;
  border: 1px solid #15a99d;
  border-radius: 5px;
  display: none;
}
.RbForm .Input.Select .Option {
  background: transparent;
  cursor: pointer;
  padding: 0 15px;
}
.RbForm .Input.Select .Option:hover {
  background: rgba(22,39,43,0.1);
  color: #00a99d;
}
.RbForm .Input.Select .Option.Selected,
.RbForm .Input.Select .Option.Selected:hover {
  background: rgba(22,39,43,0.1);
  color: #00a99d;
}
.RbForm .Input.Search input:focus ~ .Menu {
  display: block;
}
.RbForm .Input.Search .Menu {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  background-color: #fff;
  background-image: linear-gradient(180deg, #fff 0%, rgba(176,190,197,0.04) 100%);
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  color: #90a4ae;
  z-index: 1;
  font-size: 14px;
  line-height: 36px;
  padding: 2px 0;
  border: 1px solid #15a99d;
  border-radius: 5px;
  display: none;
}
.RbForm .Input.Search .Options {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.RbForm .Input.Search .Option {
  height: 60px;
  color: #b0bec5;
  padding: 0 10px;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  cursor: pointer;
}
.RbForm .Input.Search .Option:hover {
  background: rgba(22,39,43,0.1);
  color: #00a99d;
}
.RbForm .Input.Search .Option:hover .Title {
  color: #00b8ab;
}
.RbForm .Input.Search .Option.Selected {
  border-bottom: 1px solid #00b8ab;
}
.RbForm .Input.Search .Option div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(144,164,174,0.5);
}
.RbForm .Input.Search .Option .Title {
  color: #90a4ae;
}
.RbForm .Input.Search .NotFound {
  border-top: 1px solid #00b8ab;
  background: transparent;
  display: flex;
  color: rgba(144,164,174,0.7);
  padding: 10px;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
}
.RbForm .Input.Search .NotFound > :first-child {
  flex: 1 1 100%;
}
.RbForm .Input.Search .NotFound > :last-child {
  flex: 0 0 auto;
  margin-left: 20px;
}
.RbForm .Input.Search .Title,
.RbForm .Input.Search .Selected .Title {
  color: #00b8ab;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.RbForm .Input.Search .Title:before,
.RbForm .Input.Search .Selected .Title:before {
  font-size: 20px;
  margin-right: 5px;
}
.RbForm .Input.Search .AddManually {
  border-color: #00b8ab;
  margin-left: 20px;
}
.RbForm .Map .InputMap {
  height: 370px;
  border-radius: 10px;
  border: 1px solid #00b8ab;
}
.RbForm .Map .CoordinatesHelp {
  font-size: 13px;
  color: #637078;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
}
.RbForm .Map .CoordinatesColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
