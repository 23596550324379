.GZ_e4Vp2Cp1oGOjXfjtXT {
  min-width: 500px;
  overflow: visible;
}
._1K1UrD1gCVEOlQcIBBG3NS {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._1wtBwtzIWXhOL_YNTUZUC3 {
  display: flex;
  justify-content: space-between;
}
._2jEZHDdE8rTs4BJh1VcUkC {
  flex: 1;
}
._2jEZHDdE8rTs4BJh1VcUkC+._2jEZHDdE8rTs4BJh1VcUkC {
  margin-left: 5px;
}
._2pfQvDTVZxzTe-dV9Va46C {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
}
._2pfQvDTVZxzTe-dV9Va46C i {
  color: #90a4ae;
  padding-left: 50px;
}
._1619IKn3nBlP0vwOk_aAxh {
  justify-content: flex-end;
  color: #90a4ae;
  font-weight: bolder;
}
._1619IKn3nBlP0vwOk_aAxh i {
  padding: 0;
}
.Uz0HE7QFMnwZkPBgN-OlR {
  position: relative;
  font-size: 12px;
}
.Uz0HE7QFMnwZkPBgN-OlR label {
  position: absolute;
  color: #91a4af;
  background: #fff;
  top: 0;
  left: 5px;
  padding: 0 5px;
  font-size: 10px;
}
.Uz0HE7QFMnwZkPBgN-OlR textarea {
  height: 52px;
  width: calc(100% - 5px);
  border: 1px solid #91a4af;
  border-radius: 6px;
  margin: 10px 0;
  color: #91a4af;
  padding: 5px 10px;
  box-sizing: border-box;
}
._3PFD4XKJSlXl96FBWSc6GD {
  float: left;
}
._3lKmJLPEObdX59qh9joab1 {
  opacity: 0.6;
}
._1wxUxZIez6e1dZums1WQov {
  width: 200px;
}
