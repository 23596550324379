.cgDwbEjrXsz3JWfobmL2S {
  width: 450px;
  overflow: visible;
}
._1-rJjBV-6_sFAoLDCnibki {
  line-height: 2;
  font-size: 12px;
  color: #637b85;
}
._2FSoGV9AWrQm_avbYm_jsR {
  align-items: center;
  display: flex;
}
._2FSoGV9AWrQm_avbYm_jsR > ._30Ydc3INeEbIDB-bU5r0-8 {
  font-size: 12px;
}
._2FSoGV9AWrQm_avbYm_jsR ._1q0dTxb1Rv3oHKzzMGvZr6 {
  font-size: 11px;
  font-weight: normal;
}
.jFyjI4-KXErwTy5UwF6PY {
  border: 1px solid #b0bec5;
  max-height: 250px;
  overflow: auto;
}
._1HkbmRT4Pv6fsTHxxx2O1q {
  padding: 10px 5px;
  height: 42px;
  border-bottom: 1px solid #eceff1;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
}
._1HkbmRT4Pv6fsTHxxx2O1q span {
  flex: 1;
}
._2m7opD64THjOf9nqyUxHIw:hover {
  border-color: transparent;
}
._1UzfQqayrtweqaDgQ_IUji {
  display: flex;
  color: #f25b26;
  align-items: center;
}
._1UzfQqayrtweqaDgQ_IUji i {
  font-size: 14px;
}
._2rKEnJZmLOjE9HSQIkMumK {
  background: rgba(252,238,33,0.24);
}
._2Iv9Sl3au6-Lo-_v5JiNui {
  border-bottom: 3px solid #cfd8dc;
}
.Bzoe4Mjy3PgbVlsW5BHOJ {
  position: relative;
  margin-right: 10px;
}
.Bzoe4Mjy3PgbVlsW5BHOJ > img,
.Bzoe4Mjy3PgbVlsW5BHOJ > div {
  height: 65px;
  width: 110px;
  object-fit: contain;
  border-radius: 3px;
  border: 1px solid #eceff1;
  background: #eceff1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Bzoe4Mjy3PgbVlsW5BHOJ i.material-icons {
  font-size: 50px;
}
.Bzoe4Mjy3PgbVlsW5BHOJ > img {
  background: transparent;
  border: none;
}
