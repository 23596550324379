._1yGF1hcVUis85bnagLONeW {
  min-width: 500px;
  overflow: visible;
}
._2B31Stq5nm7FsQPUmTbIR6 {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
