._1qULz6Iav4o3pE1EN75Dtp {
  background: #fff;
  border: 1px solid #eceff1;
  width: 258px;
  min-height: 258px;
  border-radius: 3px;
  margin: 23px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
._3TZ5xFld6XTScVOdqLJ-0J {
  min-width: 120px;
  max-width: 258px;
  max-height: 258px;
}
._2tCTiWrwiZHr9GdrdH6x9G {
  text-align: center;
  padding: 81px 0;
  width: 100%;
  background: #eceff1;
}
._3uG4MDQSpLkD4sqHLwgiwA {
  font-size: 80px;
  text-transform: uppercase;
  color: #b0bec5;
}
._39ChHgd0m5fb_EhVPXGDLA {
  font-size: 11px;
  text-transform: uppercase;
  color: #90a4ae;
}
._33pXVHmG4VNyMX6ZIqOZkx {
  position: absolute;
  right: -1px;
  bottom: -1px;
  height: 29px;
  line-height: 27px;
  border-radius: 3px 0;
}
._2Ub_18m4p2IFSfE7bMUQA6 {
  font-size: 12px;
}
