._2oDeoRy4d9YTRVkkLYVPku {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  width: 100%;
  border: 1px solid #dcdee0;
  color: #37474f;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  text-align: center;
  padding: 0 3px;
  vertical-align: middle;
  border-radius: 20px;
  background: #fff;
}
._1QDv5uSnLJaxyO-npcYI_z {
  font-size: 8px;
  vertical-align: text-top;
  line-height: 8px;
}
