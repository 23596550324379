.preview-dialog[data-v-72bd9420] {
  max-height: 95vh;
  max-width: 95vw;
  position: relative;
}
.close[data-v-72bd9420] {
  position: absolute;
  right: -10px;
  top: 32px;
  background: #262e33;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.tabs[data-v-72bd9420] {
  margin: 5px 0;
}
.tab.active[data-v-72bd9420] {
  background: #00b8ab;
  border: #00b8ab;
  color: #fff;
}
