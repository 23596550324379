._2rD8xBa6OV1ltg3_S4B2CN {
  color: #fff;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  margin: 3px;
  border-radius: 3px;
}
._2NtE0F4TJeJgYWPlUi_XPb {
  background: #15a99d;
}
.wPYwSClzlC7-bYyPzehFj {
  background: #f15a24;
}
._23Helk35OmyNA35A_8vmjn {
  background: #cfd8dc;
  color: #78909c;
  cursor: not-allowed;
}
