.ndU5FE5NPjHcDyexqTYaE {
  height: 16px;
  padding: 0 3px;
  background: #b0bec5;
  border: 1px solid #b0bec5;
  border-radius: 3px;
  font-size: 10px;
  color: #fff;
  box-shadow: 0 0 9.46px rgba(0,0,0,0.18);
}
