._3ul6dS9X1o68Qdc7U4wkyE {
  transition: transform 0.2s ease-in-out;
  font-size: 14px !important;
  display: block;
}
._3ul6dS9X1o68Qdc7U4wkyE:hover {
  transform: scale(1.1);
}
._3FAdvPKkA-P_U9kryyz_a6 {
  position: relative;
  height: 14px;
}
._3FAdvPKkA-P_U9kryyz_a6:hover ._1p9yQ6wvw73Sh2gvjZV0Qm {
  display: block;
}
._2CEV43z6j1GSNqu7oLnjws {
  font-size: 14px;
  margin-bottom: 5px;
  color: #546f7a;
  font-weight: bold;
}
._1p9yQ6wvw73Sh2gvjZV0Qm {
  position: absolute;
  bottom: 100%;
  background: #f5f5f5;
  padding: 10px;
  padding-top: 5px;
  border-radius: 2px;
  box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.2);
  color: #546f7a;
  left: 0;
  font-size: 16px;
  display: none;
  width: 400px;
  max-height: 400px;
  overflow: auto;
  white-space: pre-wrap;
}
._1p9yQ6wvw73Sh2gvjZV0Qm:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--color);
}
