._39V-oBwwaeUl8Et9yrZ1P3 {
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._2mQaHmz3Y4fxsQ2cl_z09e {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AY089c94yk8wpgKZR2187 {
  color: #eceff1;
  font-size: 16px;
}
.AY089c94yk8wpgKZR2187:before {
  background: #00a89d;
  border-radius: 20px;
  margin-right: 10px;
  height: 40px;
  width: 40px;
  line-height: 40px !important;
}
.wwMFWhPhN9h1L-AceUYC0 {
  flex: 1 1 100%;
  background-color: #eceff1;
  overflow: hidden;
}
._3uvuRZdgJI-uYZqM3begXm,
.zFa5YIDike5ReWTGQ8UUj {
  display: flex;
  padding: 0 5px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #dedede;
  box-sizing: border-box;
}
._1yUnE5Q-RYl3WyXoU08WBE,
.YG3ue_yFKxbfDpGQlyEUS {
  box-sizing: border-box;
  width: 5%;
  max-width: 100%;
  min-width: 60px;
  padding: 0 5px;
  font-size: 13px;
  color: #546e7a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zFa5YIDike5ReWTGQ8UUj {
  background: #313c41;
}
.YG3ue_yFKxbfDpGQlyEUS {
  color: #acbec5;
}
._1AhOk-juOAIlMRPCCBrbPc {
  display: block;
  width: 300px;
  margin: 100px auto;
  background: rgba(49,60,66,0.8);
  color: #fff;
  padding: 20px;
  border: 1px solid #313c42;
  border-radius: 10px;
  text-align: center;
}
._1AhOk-juOAIlMRPCCBrbPc button {
  margin-top: 10px;
}
