._2xA0cOO0TfwV3lSerUpMSE {
  margin-right: 10px;
}
._3wj38xY_IKrNG9IWiULFLv {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  padding: 5px 0 0 0;
  border-bottom: 1px solid #eceff1;
  align-items: center;
  font-size: 14px;
}
.lFAUxmRDZxxsqkEnE82hj {
  height: auto;
  line-height: inherit;
}
.GnZ-cKcUzKCY7OawcFiAn {
  border: 2px solid #eaebec;
  padding: 0 10px 0 5px;
  height: 300px;
  overflow-y: auto;
}
.gLmt9hysfQOB1eRSFI_xH {
  flex: 1;
  text-align: left;
  margin-left: 15px;
}
._8-7WAsu-Rg3W9PtUb0yBQ {
  width: 10px;
}
._2BqlumCrthene05PnEAXN8 {
  display: flex;
  justify-content: flex-end;
  color: #cfd8dc;
  padding: 8px 0;
}
._2BqlumCrthene05PnEAXN8 * {
  cursor: pointer;
}
._2XgLNYMnP6oUr3Q8TWsQJF,
._1lGnRARKlnWNQRD5yMW4Fo {
  border-top: 5px solid #dcdee0;
}
.R10erWsBUc3LTuZAcPxL8 {
  color: #455a64;
}
.x6H8WouO6oEgWy2DB-dYF {
  font-size: 10px;
}
