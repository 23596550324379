._3jfBuFYUIH_DnDr13plgYQ {
  width: 400px;
  color: #556f7b;
  font-size: 12px;
}
._1ymG-yu_ekZ3HDGmjJkfeP {
  display: flex;
  align-items: center;
  font-weight: lighter;
}
._1ymG-yu_ekZ3HDGmjJkfeP i {
  height: 52px;
  width: 52px;
  font-size: 52px;
  color: #01a99d;
}
._3xqPzcN3NUKQq6N7diF9wQ {
  font-size: 15px;
}
._1AO-zHeDBU139cjsVKmJL9 {
  font-size: 20px;
  font-weight: bold;
}
._2H5SQ00r10nzf1c3AfFPlj {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}
._2H5SQ00r10nzf1c3AfFPlj li {
  padding: 5px 0;
  line-height: 1;
}
._16MgZcycAntRdHM8pGCcfZ {
  border-radius: 30px;
}
