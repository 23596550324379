.rbLetter {
  font-size: 14px;
  color: #546e7a;
  font-weight: normal;
  display: block;
}
.rbLetter p, .rbLetter div, .rbLetter span, .rbLetter a, .rbLetter a:hover, .rbLetter a:visited {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.rbLetter:not(.printRbLetter) .inserted {
  color: #00a99d;
}
.rbLetter p {
  margin: 20px 0 !important;
}
.rbLetter h1 {
  display: block;
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}
.rbLetter h2 {
  display: block;
  font-size: 1.7em;
  margin: 0.83em 0;
  font-weight: bold;
}
.rbLetter h3 {
  display: block;
  font-size: 1.5em;
  margin: 1em 0;
  font-weight: bold;
}
.rbLetter h4 {
  display: block;
  font-size: 1.3em;
  margin: 1.33em 0;
  font-weight: bold;
}
.rbLetter h5 {
  display: block;
  font-size: 1.17em;
  margin: 1.67em 0;
  font-weight: bold;
}
.rbLetter h6 {
  display: block;
  font-size: 1em;
  margin: 2.33em 0;
  font-weight: bold;
}
.rbLetter hr {
  display: block;
  margin: 6px 0;
  border: 0;
  border-top: 1px dashed #e1e4e6;
}
.rbLetter .insertedLogo {
  max-width: 250px;
  max-height: 150px;
}
.rbLetter .insertedTable {
  line-height: 2;
  font-size: 12px;
}
.rbLetter .insertedTable table {
  min-width: 300px;
}
.rbLetter .insertedTable table tr:first-child {
  font-weight: bold;
}
.rbLetter .inserted a {
  font-size: 14px;
  line-height: 20px;
  color: #1cb1a8;
}
.rbLetter .ViewRateQuickConfiguration {
  width: 250px;
  color: #cfd8dc;
  display: inline-flex;
  flex-direction: column;
}
.rbLetter .ViewRateQuickConfigurationRow {
  margin-top: 5px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}
.rbLetter .ViewRateQuickConfigurationRowLabel {
  font-size: 13px;
  color: #78909c;
  flex: 0 0 auto;
  margin-right: 5px;
}
.rbLetter .ViewRateQuickConfigurationRowSeparator {
  font-size: 13px;
  border-bottom: 1px dotted #cfd8dc;
  flex: 1 0 auto;
}
.rbLetter .ViewRateQuickConfigurationRowValue {
  flex: 0 0 auto;
  margin-left: 5px;
}
.rbLetter .ViewRateQuickConfigurationRowValue .inserted {
  font-size: 13px;
  color: #b0bec5;
}
.rbLetter .ViewRateQuickLogoSubtitle .inserted {
  color: #546e7a;
}
.rbLetter .ViewRateQuickRfpTitle {
  margin: 0;
}
.rbLetter .ViewRateQuickRfpTitle .inserted {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #455a64;
}
