._3yiaBaOJkgyVj8anLEz8PK {
  display: flex;
  min-height: 74px;
  color: #fff;
  margin-bottom: 2px;
  box-sizing: border-box;
  overflow: hidden;
}
._1eIUaorn2SUhmAqSUSjEaO {
  width: 113px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #eceff1;
  background-color: #fff;
  height: 72px;
  object-fit: contain;
  margin-right: 5px;
}
._3BZs0dn7IB_HL4ziSeMwn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  background: rgba(0,0,0,0.5);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
._2R07JnOeqNh2r6xW7jkjn6 {
  min-height: 36px;
  background-color: rgba(242,60,24,0.8);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: 2px;
  display: flex;
  position: relative;
  border-radius: 5px;
}
._2R07JnOeqNh2r6xW7jkjn6 > * {
  vertical-align: middle;
}
._3TMOD6Y01Oe5E6IccM_aZP:hover {
  background-color: #313c41;
}
._3NjFAqvQB0a5oogbaTg6Wz {
  padding: 6px;
  display: flex;
  width: 100%;
  align-items: center;
}
._1y-1IVdait7OKQKJMn8dNY {
  background: rgba(0,0,0,0.5);
}
.X4tjMk9wTkj9_0se8gQw0 {
  background-color: #313c41;
}
._2aKhEhkhKglm4Bv4ObRsiG {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
._10cGRgdge-Sfg1jtfT8TDX {
  flex: 1;
  word-break: break-word;
}
._23B7R5y7UYMY6wJSy_1HqL {
  color: rgba(211,219,222,0.471);
}
._2JaAbO8dAURDwaUSXg_ubE {
  cursor: pointer;
}
._6TfM6e1FfAZDD19L2dvbC {
  min-height: 65px;
}
.BywpPtjy6n_kKlcOg4wcS {
  font-size: 22px !important;
}
._2fXbA-NdAH1J5FgF9J-x9A {
  color: rgba(242,60,24,0.8);
  padding: 20px 6px;
}
