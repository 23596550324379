.Mwyfs8woS-Pay0VnJw846 {
  width: 100%;
  border: 1px solid #d1d3d4;
  text-align: left;
  font-size: 12px;
  color: #627b85;
  border-collapse: collapse;
}
.Mwyfs8woS-Pay0VnJw846 td,
.Mwyfs8woS-Pay0VnJw846 th {
  padding: 5px;
  border: 1px solid #d1d3d4;
}
.Mwyfs8woS-Pay0VnJw846 thead {
  background: #ebeeef;
}
._1y8U_5lJNuVB63NR_6Foih {
  text-align: center;
}
.pfKrvlMsvN8XqAZ68xBU- {
  display: flex;
  justify-content: flex-start;
  font-weight: lighter;
  align-items: center;
}
.pfKrvlMsvN8XqAZ68xBU- > span {
  flex: 1;
  text-align: center;
}
.H4FAF0UDJh3P4vffdEoL_ {
  display: flex;
  justify-content: space-evenly;
}
