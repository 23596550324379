._35VCi1xq9FaoJURDe3gWoX {
  width: 500px;
  overflow: visible;
}
._1t-C-F3w5ARJrKkvbLKP9g {
  font-size: 12px;
  line-height: 14pt;
  color: #78909c;
  font-weight: lighter;
}
._2-XaNfVbY9hkXBdrOBknlb {
  float: left;
}
.eE6TfSTcGTqJ71EV5bX-1 {
  font-size: 12px;
  text-align: center;
  color: #556e7b;
}
