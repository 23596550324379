._1Qw-sO6JIqjKJSJqRo7quY {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 20px auto;
  display: flex;
  max-width: 95%;
}
._1zo29LJOAW7CPH1C200fnS {
  width: 304px;
  background: #fff;
  margin-right: 1px;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 0 3.14px rgba(0,0,0,0.18);
}
.LCihuztgr9SPrglbWJeNr {
  background: #fff;
  min-width: 624px;
  border-radius: 0 6px 6px 0;
  box-shadow: 0 0 7.53px rgba(0,0,0,0.18);
}
