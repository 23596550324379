.rb-action-select {
  border-radius: 50px;
  height: 35px;
}
.rb-action-select+.rb-action-select {
  margin-left: 10px;
}
.rb-action-select .filter {
  position: relative;
  background: transparent !important;
}
.rb-action-select .filter.inactive {
  color: #b0bec5 !important;
}
.rb-action-select .filter:not(.inactive) i.material-icons:first-child,
.rb-action-select .filter:not(.inactive) .label {
  color: #329188;
}
.rb-action-select .filter:not(.inactive) .label {
  position: absolute;
  top: -10px;
  font-size: 12px;
  left: 15px;
  background: #fff;
}
.rb-action-select .filter:not(.inactive).dark .label {
  background: #313c42;
}
.rb-action-select .filter .label {
  transition: all 0.3s;
}
.rb-action-select .filter * {
  vertical-align: middle;
}
.rb-action-select .backdrop {
  background-color: transparent !important;
}
.rb-action-select.dark-background .filter {
  background: #263238 !important;
}
.rb-action-select .item.desc .icon2 {
  transform: rotateX(180deg);
}
.rb-action-select .input {
  border: none;
  margin-left: 5px;
  padding: 5px;
  border-radius: 2px;
}
